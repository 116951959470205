import React from 'react';
import { 
  TreePine,
  Search,
  Camera,
  ImagePlus,
  Trees,
  Flame,
  FileSpreadsheet
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import SharedNav from '../components/landing-nav';
import { useEffect } from 'react';
import { useMobile } from '../contexts/app-context';

export default function AboutPage() {
  const isMobile = useMobile();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const problems = [
    {
      title: "Existing Apps Fall Short",
      description: "Traditional tree identification apps focus legacy technology, but what about AI? That's where TreeID comes in - purpose-built for wood identification.",
      icon: <Search className={isMobile ? "h-10 w-10" : "h-8 w-8"} />,
    },
    {
      title: "A Growing Need",
      description: "From r/firewood to Facebook groups, thousands of people ask for wood identification help daily. TreeID provides instant, accurate answers.",
      icon: <Trees className={isMobile ? "h-10 w-10" : "h-8 w-8"} />,
    },
    {
      title: "BBQ Enthusiast",
      description: "Living in Kansas City, the BBQ capital of the world, I needed to make sure the wood I use for smoking meat is the right type. TreeID helps me get it right every time.",
      icon: <Flame className={isMobile ? "h-10 w-10" : "h-8 w-8"} />,
    },
  ];

  const solutions = [
    {
      title: "Instant Analysis",
      description: "Upload a photo and get detailed wood species information immediately. Share your findings with others in the community.",
      icon: <Camera className={isMobile ? "h-10 w-10" : "h-8 w-8"} />,
    },
    {
      title: "Buyer Protection",
      description: "Never overpay for firewood again. Verify wood species before purchase with our instant identification system.",
      icon: <FileSpreadsheet className={isMobile ? "h-10 w-10" : "h-8 w-8"} />,
    },
    {
      title: "Community Sharing",
      description: "Build your knowledge by sharing identifications and learning from others. Perfect for property owners learning about their trees.",
      icon: <ImagePlus className={isMobile ? "h-10 w-10" : "h-8 w-8"} />,
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50">
      <SharedNav />

      <main className={isMobile ? "pt-24" : "pt-32 md:pt-40"}>
        {/* Hero Section */}
        <section className={`${isMobile ? 'pb-12 px-4' : 'pb-16 md:pb-24 px-6'}`}>
          <div className="max-w-4xl mx-auto text-center space-y-10">
            <div className="flex justify-center">
              <div className={`bg-gradient-to-br from-emerald-400 to-teal-500 ${isMobile ? 'p-8' : 'p-5 md:p-6'} rounded-3xl shadow-lg`}>
                <TreePine className={`${isMobile ? 'h-20 w-20' : 'h-12 w-12 md:h-16 md:w-16'} text-white`} />
              </div>
            </div>
            <h1 className={`${isMobile ? 'text-5xl' : 'text-5xl md:text-6xl lg:text-7xl'} font-bold bg-gradient-to-r from-emerald-800 to-teal-700 bg-clip-text text-transparent leading-tight pb-2`}>
              The Story Behind TreeID
            </h1>
          </div>
        </section>

        {/* Personal Story Section */}
        <section className={`${isMobile ? 'py-12' : 'py-16 md:py-24'} bg-white`}>
          <div className={`max-w-3xl mx-auto ${isMobile ? 'px-4' : 'px-6'} space-y-8`}>
            <p className={`${isMobile ? 'text-2xl' : 'text-xl md:text-2xl'} text-emerald-800 leading-relaxed`}>
              When I moved to my acreage, I faced a common challenge - I had no idea what types of trees I had on my property. Traditional tree identification apps focused on leaves and bark, but I needed something more comprehensive.
            </p>
            <p className={`${isMobile ? 'text-2xl' : 'text-xl md:text-2xl'} text-emerald-800 leading-relaxed`}>
              I noticed the same frustration in online communities. Every day, hundreds of people post photos asking "What type of wood is this?" Whether for firewood, woodworking, or property management, people needed reliable wood identification.
            </p>
            <p className={`${isMobile ? 'text-2xl' : 'text-xl md:text-2xl'} text-emerald-800 leading-relaxed`}>
              That's why I built TreeID - a purpose-built solution for instant, accurate wood identification. No more guessing, no more overpaying for firewood, and no more uncertainty about the trees on your property.
            </p>
          </div>
        </section>

        {/* Problems Section */}
        <section className={`${isMobile ? 'py-12 px-3' : 'py-8 md:py-24'}`}>
          <div className="max-w-7xl mx-auto px-4 md:px-6">
            <h2 className={`${isMobile ? 'text-4xl mb-8' : 'text-2xl md:text-4xl mb-8 md:mb-12'} font-bold text-emerald-800 text-center`}>
              The Problems We Solve
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
              {problems.map((problem) => (
                <Card
                  key={problem.title}
                  className="group bg-white hover:shadow-xl transition-all duration-300 flex flex-col h-full"
                >
                  <CardHeader className={`${isMobile ? 'p-6' : 'p-4 md:p-6'}`}>
                    <div className="flex items-start md:items-center gap-4">
                      <div className={`bg-gradient-to-br from-emerald-500 to-teal-500 ${isMobile ? 'p-5' : 'p-3 md:p-4'} rounded-2xl text-white shadow-md`}>
                        {problem.icon}
                      </div>
                      <CardTitle className={`${isMobile ? 'text-2xl' : 'text-xl md:text-2xl'} font-semibold text-emerald-800`}>
                        {problem.title}
                      </CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent className={`flex-1 ${isMobile ? 'p-6 pt-0' : 'p-4 md:p-6 pt-0 md:pt-0'}`}>
                    <p className={`${isMobile ? 'text-xl' : 'text-base md:text-lg'} text-emerald-600 leading-relaxed`}>
                      {problem.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Solutions Section */}
        <section className={`${isMobile ? 'py-12 px-3' : 'py-8 md:py-24'} bg-white`}>
          <div className="max-w-7xl mx-auto px-4 md:px-6">
            <h2 className={`${isMobile ? 'text-4xl mb-8' : 'text-2xl md:text-4xl mb-8 md:mb-12'} font-bold text-emerald-800 text-center`}>
              Our Solutions
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
              {solutions.map((solution) => (
                <Card
                  key={solution.title}
                  className="group bg-gradient-to-br from-emerald-50 to-teal-50 hover:shadow-xl transition-all duration-300 flex flex-col h-full"
                >
                  <CardHeader className={`${isMobile ? 'p-6' : 'p-4 md:p-6'}`}>
                    <div className="flex items-start md:items-center gap-4">
                      <div className={`bg-gradient-to-br from-emerald-500 to-teal-500 ${isMobile ? 'p-5' : 'p-3 md:p-4'} rounded-2xl text-white shadow-md`}>
                        {solution.icon}
                      </div>
                      <CardTitle className={`${isMobile ? 'text-2xl' : 'text-xl md:text-2xl'} font-semibold text-emerald-800`}>
                        {solution.title}
                      </CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent className={`flex-1 ${isMobile ? 'p-6 pt-0' : 'p-4 md:p-6 pt-0 md:pt-0'}`}>
                    <p className={`${isMobile ? 'text-xl' : 'text-base md:text-lg'} text-emerald-600 leading-relaxed`}>
                      {solution.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}