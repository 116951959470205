import React from 'react';
import { Link } from 'react-router-dom';
import { TreePine, Heart } from 'lucide-react';

const Footer = () => {
  const year = new Date().getFullYear();

  const links = [
    { name: 'Story', href: '/about' },
    { name: 'Privacy', href: '/privacy' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Signup', href: '/signup' },
    { name: "Terms", href: "/terms" },
  ];

  return (
    <footer className="bg-white border-t border-emerald-100">
      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          {/* Logo and Links */}
          <div className="flex flex-col md:flex-row items-center gap-8">
            {/* Brand */}
            <Link to="/" className="flex items-center gap-2 group">
              <div className="bg-gradient-to-br from-emerald-400 to-emerald-600 p-2 rounded-lg group-hover:shadow-md transition-shadow">
                <TreePine className="h-5 w-5 text-white" />
              </div>
              <span className="text-xl font-bold bg-gradient-to-r from-emerald-800 to-emerald-600 bg-clip-text text-transparent">
                TreeID
              </span>
            </Link>

            {/* Links */}
            <nav className="flex items-center gap-6">
              {links.map((link) => (
                <Link 
                  key={link.name}
                  to={link.href}
                  className="text-emerald-600 hover:text-emerald-700 text-sm"
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>

          {/* Copyright */}
          <div className="flex items-center gap-2 text-sm text-emerald-600">
            <span></span>
            <span>© {year}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;