import React from 'react';

const AngledImage = ({ 
  src, 
  alt, 
  className = "", 
  angle = "3" 
}) => {
  return (
    <div 
      className={`relative ${className}`}
      style={{
        transform: `perspective(1000px) rotateY(${angle}deg)`,
        transformStyle: 'preserve-3d'
      }}
    >
      <img
        src={src}
        alt={alt}
        className="rounded-xl shadow-2xl w-full h-full object-cover"
      />
    </div>
  );
};

export default AngledImage;