import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TreePine } from 'lucide-react';
import { Button } from './ui/button';

const SubscriptionRequired = () => {
  const navigate = useNavigate();
  
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] text-center px-4">
      <div className="bg-emerald-100 p-6 rounded-full mb-6">
        <TreePine className="h-16 w-16 text-emerald-600" />
      </div>
      <h3 className="text-2xl font-semibold text-gray-900 mb-4">Subscription Required</h3>
      <p className="text-lg text-gray-600 max-w-md leading-relaxed mb-6">
        You are not currently subscribed. Try refreshing the page or start your free trial to access tree identification and analysis.
      </p>
      <Button 
        onClick={() => navigate('/signup')} 
        className="bg-emerald-600 hover:bg-emerald-700 text-white"
      >
        Start Free Trial
      </Button>
    </div>
  );
};

export default SubscriptionRequired;