import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';
import Logo from '../components/logo';
import SteppedSignup from '../components/stepped-signup';
import { useMobile } from '../contexts/app-context';
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../components/ui/sheet";

const SignupPage = () => {
  const isMobile = useMobile();

  const navItems = [
    { name: 'Pricing', href: '/pricing' },
    { name: 'About', href: '/about' },
    { name: 'Contact', href: '/contact' }
  ];

  const NavLinks = () => (
    <>
      {navItems.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          className={`
            text-emerald-600 transition-colors block
            ${isMobile 
              ? 'text-lg py-4 hover:text-emerald-800 border-b border-emerald-100/20' 
              : 'text-base hover:text-emerald-700'
            }
          `}
        >
          {item.name}
        </Link>
      ))}
    </>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-emerald-50 to-teal-50">
      <nav className="fixed w-full bg-white/95 backdrop-blur-sm border-b border-emerald-100 z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className={`flex items-center justify-between ${isMobile ? 'h-14' : 'h-20'}`}>
            <Logo />
            
            {isMobile ? (
              <Sheet>
                <SheetTrigger asChild>
                  <button 
                    className="p-2 text-emerald-600 rounded-lg"
                    aria-label="Open menu"
                  >
                    <Menu className="h-6 w-6" />
                  </button>
                </SheetTrigger>
                <SheetContent 
                  side="right" 
                  className="w-72 bg-white/95 backdrop-blur-sm pt-8 px-4"
                >
                  <div className="flex flex-col space-y-1 mt-4">
                    <NavLinks />
                  </div>
                </SheetContent>
              </Sheet>
            ) : (
              <div className="flex items-center gap-8">
                <NavLinks />
              </div>
            )}
          </div>
        </div>
      </nav>

      <main className={`
        ${isMobile ? 'pt-20' : 'pt-28'} 
        px-4 pb-8 
        max-w-7xl mx-auto
      `}>
        <SteppedSignup />
      </main>
    </div>
  );
};

export default SignupPage;