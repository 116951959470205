import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Progress } from './ui/progress';
import {
  TreePine, Leaf, AlertCircle, Plus,
  Loader2, Info, Calendar, Share2, MessageCircle
} from 'lucide-react';
import { useToast } from '../hooks/use-toast';
import { postTreesByTreeIdAnalysis } from '../client/sdk.gen';
import { copyToClipboard } from './toast-provider';
import { useApp } from '../contexts/app-context';
import TreeChatDialog from './tree-chat-dialog';
import { cn } from '../lib/utils';
import { useMobile } from '../contexts/app-context';


const Analysis = () => {
  const { selectedTree, loadTreeDetails, setSelectedTree, updateTree } = useApp();
  const { toast } = useToast();
  const [isAnalyzing, setIsAnalyzing] = React.useState(false);
  const [isSharing, setIsSharing] = React.useState(false);
  const [isChatMinimized, setIsChatMinimized] = React.useState(true);
  const isMobile = useMobile();

  const handleAnalyzeClick = async () => {
    if (!selectedTree?.id) return;
    setIsAnalyzing(true);
    try {
      const resp = await postTreesByTreeIdAnalysis({
        path: { treeId: selectedTree.id },
        body: {},
      });

      if (resp.status === 429) {
        const minutesRemaining = Math.ceil(resp.response.data.remainingSeconds / 60);
        toast({
          title: 'Rate limit exceeded',
          description: minutesRemaining ? `Please wait about ${minutesRemaining} minute(s) to try again.` : 'Please wait a bit before trying again.',
          variant: 'destructive'
        });
        return;
      }

      setSelectedTree(await loadTreeDetails(selectedTree.id, true));
      toast({
        title: "Success",
        description: "Tree analysis completed successfully",
        className: "bg-white border-2 border-emerald-600",
      });
    } catch (error) {
      console.error('Error analyzing tree:', error);
      toast({
        title: "Error",
        description: "Failed to analyze tree",
        variant: "destructive"
      });
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleShare = async () => {
    if (!selectedTree?.id) return;
    
    if (!selectedTree.isPublic) {
      await updateTree({ ...selectedTree, isPublic: true });
    }
    
    setIsSharing(true);
    try {
      await copyToClipboard(
        `${window.location.origin}/trees/public/${selectedTree.id}`,
        (options) => toast({
          ...options,
          className: "bg-white border-l-4 border-l-emerald-500 shadow-md",
          description: "Link copied to clipboard",
          duration: 2000 // 2 seconds
        })
      );
    } finally {
      setIsSharing(false);
    }
  };

  if (!selectedTree) {
    return (
      <Card className={cn(
        "w-full bg-white",
        isMobile ? "shadow-sm" : "shadow-lg"
      )}>
        <CardContent className={cn(
          "flex items-center justify-center",
          isMobile ? "p-6" : "p-12"
        )}>
          <div className="text-center space-y-6">
            <div className={cn(
              "bg-emerald-100 rounded-full flex items-center justify-center mx-auto",
              isMobile ? "w-16 h-16" : "w-24 h-24"
            )}>
              <TreePine className={cn(
                "text-emerald-600",
                isMobile ? "h-8 w-8" : "h-12 w-12"
              )} />
            </div>
            <p className={cn(
              "text-gray-600",
              isMobile ? "text-lg" : "text-2xl"
            )}>Select a tree to view analysis</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const analysis = selectedTree.analyses;

  if (isAnalyzing) {
    return (
      <Card className={cn(
        "w-full bg-white",
        isMobile ? "shadow-sm" : "shadow-lg"
      )}>
        <CardContent className={cn(
          isMobile ? "p-6" : "p-12"
        )}>
          <div className="flex flex-col items-center justify-center gap-8">
            <Loader2 className={cn(
              "text-emerald-500 animate-spin",
              isMobile ? "h-12 w-12" : "h-16 w-16"
            )} />
            <div className="text-center space-y-3">
              <p className={cn(
                "font-medium text-emerald-800",
                isMobile ? "text-xl" : "text-2xl"
              )}>Analyzing your tree...</p>
              <p className={cn(
                "text-emerald-600",
                isMobile ? "text-base" : "text-lg"
              )}>This may take a few moments</p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!analysis?.message) {
    return (
      <Card className="w-full bg-white shadow-lg">
        <CardHeader className="border-b border-gray-100 p-8">
          <CardTitle className="text-3xl text-emerald-800 flex items-center gap-3">
            <Leaf className="h-8 w-8 text-emerald-600" />
            Tree Analysis
          </CardTitle>
        </CardHeader>
        <CardContent className={cn(
  "space-y-8",
  isMobile ? "p-4" : "p-8"
)}>
          <Alert className="bg-emerald-50 border-emerald-200">
            <AlertCircle className="h-6 w-6 text-emerald-600" />
            <AlertTitle className="text-2xl text-emerald-800">No Analysis Available</AlertTitle>
            <AlertDescription className="text-lg text-emerald-600">
              Click analyze to identify this tree and get detailed information.
            </AlertDescription>
          </Alert>
          
          <Button 
            onClick={handleAnalyzeClick}
            className="w-full h-16 text-lg bg-gradient-to-r from-emerald-500 to-emerald-600 
              hover:from-emerald-600 hover:to-emerald-700 text-white shadow-lg"
          >
            <Plus className="h-6 w-6 mr-3" />
            Start Analysis
          </Button>
        </CardContent>
      </Card>
    );
  }

  const confidence = analysis.confidence * 100;

  return (
    <>
      <Card className="w-full bg-white shadow-lg">
      <CardHeader className={cn(
  "border-b border-gray-100",
  isMobile ? "p-4" : "p-8"
)}>
  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
    <CardTitle className={cn(
      "text-emerald-800 flex items-center gap-3",
      isMobile ? "text-2xl" : "text-3xl"
    )}>
      <Leaf className={cn(
        "text-emerald-600",
        isMobile ? "h-6 w-6" : "h-8 w-8"
      )} />
      Analysis Results
    </CardTitle>
    {analysis.analysis_date && (
      <div className={cn(
        "flex items-center gap-2 text-gray-500",
        isMobile ? "text-base" : "text-lg"
      )}>
        <Calendar className="h-5 w-5" />
        {new Date(analysis.analysis_date).toLocaleDateString()}
      </div>
    )}
  </div>
</CardHeader>

        <CardContent className={cn(
  "space-y-8",
  isMobile ? "p-4" : "p-8"
)}>
          <div className="rounded-2xl bg-gradient-to-br from-emerald-50 via-emerald-100/30 to-emerald-50 p-8">
            <div className="space-y-6">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-3xl font-bold text-emerald-900 mt-1">{analysis.tree_type}</p>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4 group">
                  <button 
                    onClick={() => updateTree({ ...selectedTree, isPublic: !selectedTree.isPublic })}
                    className={`
                      relative w-32 h-10 rounded-full transition-colors duration-200 ease-in-out
                      ${selectedTree.isPublic ? 'bg-emerald-100' : 'bg-gray-100'}
                    `}
                  >
                    <div className={`
                      absolute inset-y-1 flex items-center
                      transition-all duration-300 ease-in-out
                      ${selectedTree.isPublic ? 'right-1' : 'left-1'}
                    `}>
                      <div className={`
                        flex items-center justify-center w-8 h-8 rounded-full
                        ${selectedTree.isPublic ? 'bg-emerald-600' : 'bg-gray-400'}
                      `}>
                        <TreePine className="h-4 w-4 text-white" />
                      </div>
                    </div>
                    <span className={`
                      absolute inset-0 flex items-center 
                      ${selectedTree.isPublic ? 'justify-start pl-4' : 'justify-end pr-4'}
                      text-sm font-medium
                      ${selectedTree.isPublic ? 'text-emerald-700' : 'text-gray-600'}
                    `}>
                      {selectedTree.isPublic ? 'Public' : 'Private'}
                    </span>
                  </button>
                </div>

                <Button
                  onClick={handleShare}
                  variant="outline"
                  className="p-4 rounded-xl border border-emerald-100"
                >
                  <Share2 className="h-8 w-8 text-emerald-600" />
                </Button>
              </div>
              
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-medium text-emerald-700">
                    Confidence Score
                  </span>
                  <span className="text-lg font-bold text-emerald-800">
                    {confidence.toFixed(1)}%
                  </span>
                </div>
                <Progress 
                  value={confidence}
                  className="h-3 bg-emerald-100"
                />
              </div>
            </div>
          </div>

          <div className="rounded-2xl bg-gradient-to-br from-white to-emerald-50/30 border border-emerald-100">
            <div className="p-8 space-y-6">
              <div className="flex items-center gap-4">
                <div className="bg-emerald-100 p-3 rounded-xl">
                  <Info className="h-7 w-7 text-emerald-600" />
                </div>
                <h3 className="text-2xl sm:text-3xl font-medium text-emerald-800">Expert Analysis</h3>
              </div>
              
              <p className="text-xl sm:text-2xl text-gray-700 leading-relaxed pl-4 pr-2">
                {analysis.message}
              </p>
            </div>
          </div>

          {analysis.matches?.length > 0 && (
            <div className="space-y-6">
              <h3 className="text-2xl font-medium text-emerald-800 flex items-center gap-3">
                <TreePine className="h-6 w-6 text-emerald-600" />
                Similar Species
              </h3>
              
              <div className="grid gap-6 sm:grid-cols-2">
                {analysis.matches.map((match, index) => {
                  const matchConfidence = match.confidence * 100;
                  return (
                    <div 
                      key={index}
                      className="bg-gradient-to-br from-white to-gray-50 rounded-xl border border-gray-100"
                    >
                      <div className="p-6 space-y-4">
                        <div className="flex justify-between items-start gap-4">
                          <span className="text-xl font-semibold text-gray-900">
                            {match.tree_type}
                          </span>
                          <div className="bg-emerald-50 px-4 py-2 rounded-full border border-emerald-100">
                            <span className="text-lg font-bold text-emerald-600">
                              {matchConfidence.toFixed(1)}%
                            </span>
                          </div>
                        </div>
                        
                        <Progress 
                          value={matchConfidence}
                          className="h-3 bg-emerald-100"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <Button
            onClick={handleShare}
            disabled={isSharing}
            className="h-14 bg-blue-600 text-lg font-medium text-white hover:bg-blue-700"
          >
            <Share2 className={`h-6 w-6 mr-2 ${isSharing ? 'animate-pulse' : ''}`} />
            {isSharing ? 'Sharing...' : 'Share Analysis'}
          </Button>
        </CardContent>      
      </Card>
      {selectedTree?.id && (
        <TreeChatDialog 
        />
      )}
    </>
  );
};

export default Analysis;