import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { 
  TreePine, 
  Upload,
  Home,
  LogOut,
  UserCircle2,
  Menu
} from 'lucide-react';
import { useLogout } from '../hooks/use-logout';

interface NavigationProps {
  onUploadClick: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ onUploadClick }) => {
  const { user } = useAuth0();
  const location = useLocation();
  const [showLogoutMenu, setShowLogoutMenu] = React.useState(false);
  const handleLogout = useLogout();

  // Navigation items configuration
  const navItems = [
    { 
      icon: Home, 
      label: 'Home', 
      path: '/dashboard',
      onClick: () => {} 
    },
    { 
      icon: Upload, 
      label: 'New', 
      isSpecial: true,
      onClick: onUploadClick 
    },
    { 
      icon: UserCircle2, 
      label: 'Account', 
      path: '/account',
      onClick: () => {} 
    },
    {
      icon: UserCircle2,
      label: 'Support',
      path: '/support',
      onClick: () => {}
    }
  ];

  const isActive = (path?: string) => {
    if (!path) return false;
    return location.pathname === path;
  };

  return (
    <>
      {/* Minimal Top Bar - Mobile */}
      <div className="fixed top-0 left-0 right-0 z-50 md:hidden">
        <div className="bg-white/95 backdrop-blur-sm border-b border-emerald-100 px-4 h-16 flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2 p-2">
            <div className="bg-gradient-to-br from-emerald-400 to-emerald-600 p-2 rounded-lg">
              <TreePine className="h-6 w-6 text-white" />
            </div>
            <span className="font-semibold text-emerald-900">TreeID</span>
          </Link>

          <button 
            onClick={() => setShowLogoutMenu(true)}
            className="p-4 hover:bg-gray-100 rounded-full"
          >
            <Menu className="h-6 w-6 text-gray-600" />
          </button>

          {/* Mobile Logout Menu */}
          {showLogoutMenu && (
            <div className="fixed inset-0 bg-black/50 z-50" onClick={() => setShowLogoutMenu(false)}>
              <div className="absolute bottom-0 left-0 right-0 bg-white rounded-t-3xl p-6 space-y-4">
                <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-6" />
                
                {user?.picture && (
                  <div className="flex items-center gap-3 p-6 bg-gray-50 rounded-2xl">
                    <img src={user.picture} alt="Profile" className="w-14 h-14 rounded-full" />
                    <div>
                      <div className="font-medium text-gray-900 text-lg">{user.name}</div>
                      <div className="text-gray-500">{user.email}</div>
                    </div>
                  </div>
                )}
                
                <button
                  onClick={() => {
                    setShowLogoutMenu(false);
                    handleLogout();
                  }}
                  className="w-full flex items-center justify-center gap-3 p-6 bg-red-50 
                           text-red-600 rounded-2xl font-medium hover:bg-red-100 transition-colors
                           text-lg"
                >
                  <LogOut className="h-6 w-6" />
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden md:block border-b bg-white/95 backdrop-blur-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <Link to="/dashboard" className="flex items-center gap-2">
              <div className="bg-gradient-to-br from-emerald-400 to-emerald-600 p-2 rounded-lg">
                <TreePine className="h-6 w-6 text-white" />
              </div>
              <span className="font-semibold text-emerald-900 text-lg">TreeID</span>
            </Link>

            <div className="flex items-center gap-2">
              {navItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.path || '#'}
                  onClick={item.onClick}
                  className={`px-6 py-3 rounded-full flex items-center gap-3 transition-colors
                    ${isActive(item.path) 
                      ? 'bg-emerald-100 text-emerald-700' 
                      : 'hover:bg-gray-100 text-gray-600'}`}
                >
                  <item.icon className="h-6 w-6" />
                  <span className="text-lg">{item.label}</span>
                </Link>
              ))}
            </div>

            {user?.picture && (
              <div className="flex items-center gap-4">
                <button
                  onClick={handleLogout}
                  className="px-6 py-3 text-red-600 hover:bg-red-50 rounded-full flex items-center gap-3 
                          transition-colors text-lg"
                >
                  <LogOut className="h-6 w-6" />
                  Sign Out
                </button>
                <Link to="/account">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="w-12 h-12 rounded-full border-2 border-emerald-200 cursor-pointer"
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Mobile Bottom Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 z-50 bg-white border-t border-gray-200">
        <div className="flex justify-around items-center px-2">
          {navItems.map((item, index) => {
            const Icon = item.icon;
            const active = isActive(item.path);

            if (item.isSpecial) {
              return (
                <button
                  key={index}
                  onClick={item.onClick}
                  className="transform -translate-y-4"
                >
                  <div className="flex flex-col items-center">
                    <div className="bg-gradient-to-r from-emerald-500 to-emerald-600 
                                  p-5 rounded-full shadow-lg">
                      <Icon className="h-8 w-8 text-white" />
                    </div>
                    <span className="text-sm mt-1 text-gray-600">{item.label}</span>
                  </div>
                </button>
              );
            }

            return (
              <Link
                key={index}
                to={item.path || '#'}
                onClick={item.onClick}
                className="flex-1 flex flex-col items-center py-3 px-2"
              >
                <div className={`p-3 rounded-full ${
                  active ? 'text-emerald-600' : 'text-gray-600'
                }`}>
                  <Icon className="h-8 w-8" />
                </div>
                <span className={`text-sm mt-1 ${
                  active ? 'text-emerald-600 font-medium' : 'text-gray-600'
                }`}>
                  {item.label}
                </span>
              </Link>
            );
          })}
        </div>
        
        {/* iOS Safe Area */}
        <div className="h-[env(safe-area-inset-bottom)] bg-white" />
      </div>

      {/* Content Spacing for Mobile */}
      <div className="md:hidden h-16" /> {/* Top spacing - increased */}
      <div className="md:hidden h-24" /> {/* Bottom spacing - increased */}
    </>
  );
};

export default Navigation;