import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import {
  getTrees,
  getTreesByTreeId,
  putTreesByTreeId,
  deleteTreesByTreeId,
  postTrees,
  getFilesUploadUrl,
  postFilesCompleteUpload,
  getFilesByFileId,
  getSubscriptionPass,
  client,
  deleteQaByQaId,
  getQaByTreeId,
  postQaByTreeId,
  getSupport,
  getApiSubscriptions,
} from '../client/sdk.gen';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '../hooks/use-toast';
import config from '../config';
import { Tree, TreeDetailed, QaItem, SubscriptionResponse } from '../client/types.gen';
import { useLocation } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../App';
import posthog from 'posthog-js';


interface AppContextType extends AppState {
  loadTrees: () => Promise<void>;
  updateTree: (updatedTree: Tree) => void;
  deleteTree: (treeId: string, onSuccess?: () => void) => Promise<void>;
  setSelectedTree: (tree: TreeDetailed | null) => void;
  handleFilesUpload: (files: File[], onSuccess?: (treeId: string) => void) => Promise<void>;
  setIsUploadOpen: (isOpen: boolean) => void;
  resetState: () => void;
  loadTreeDetails: (treeId: string, forceRefresh?: boolean) => Promise<TreeDetailed | null>;
  fetchSubscription: () => Promise<void>;
  loadQAs: (treeId: string) => Promise<QaItem[]>;
  addQA: (treeId: string, question: string) => Promise<QaItem>;
  deleteQA: (qaId: string, treeId: string) => Promise<void>;
  getSupportEmail: () => Promise<string>;
  getSubscriptionDetails: () => Promise<SubscriptionResponse | null>;
  track: (event: string, properties?: Record<string, any>) => void;
}

interface AppState {
  trees: Tree[];
  selectedTree: TreeDetailed | null;
  imageUrls: Record<string, string>;
  treeDetailsCache: Record<string, TreeDetailed>;
  isLoading: boolean;
  isInitialized: boolean;
  isUploading: boolean;
  uploadProgress: number;
  isUploadOpen: boolean;
  subscriptionPass: string | null;
  qaCache: Record<string, QaItem[]>;
  subscriptionCache: SubscriptionResponse | null;
  subscriptionCacheExpiry: number | null;
  isChatMinimized: boolean;
  setIsChatMinimized: (isMinimized: boolean) => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  isLoggingOut: boolean;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

const initialState: AppState = {
  trees: [],
  selectedTree: null,
  imageUrls: {},
  treeDetailsCache: {},
  isLoading: false,
  isInitialized: false,
  isUploading: false,
  uploadProgress: 0,
  isUploadOpen: false,
  subscriptionPass: null,
  qaCache: {},
  subscriptionCache: null,
  subscriptionCacheExpiry: null,
  isChatMinimized: true,
  isMobile: false,
  setIsMobile: () => {},
  isLoggingOut: false,
};

export function AppProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const isPublicRoute = PUBLIC_ROUTES.some((route: string) =>
    location.pathname.startsWith(route)
  );

  // Initialize PostHog
  useEffect(() => {
    posthog.init('phc_wLJH15FZFIvuMiF7CkTK1qKdYRkx8lwN3pZ3HLzYY9s', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only'
    });
  }, []);

  const { user } = useAuth0();
  useEffect(() => {
    if (user?.sub) {
      posthog.identify(user.sub, {
        email: user.email,
        name: user.name
      });
    }
  }, [user]);

  const BREAKPOINTS = {
    MOBILE: 768,
    TABLET: 1024,
  } as const;
  

  const isMobileDevice = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobileUA = /mobile|iphone|ipad|android|windows phone/.test(userAgent);
    const isTablet = /ipad|tablet/.test(userAgent);
    const isSmallScreen = window.innerWidth <= 1024; // Increased breakpoint
    
    // Consider it mobile if either:
    // 1. It's a mobile user agent and not a tablet
    // 2. It's a very small screen (regardless of user agent)
    return (isMobileUA && !isTablet) || window.innerWidth <= 768;
  };
  
  // Replace the existing checkMobile function in useEffect
  useEffect(() => {
    const checkMobile = () => {
      const mobile = isMobileDevice();
      setState(prev => prev.isMobile !== mobile ? { ...prev, isMobile: mobile } : prev);
    };
  
    // Check initially
    checkMobile();
  
    // Add event listener
    window.addEventListener('resize', checkMobile);
    window.addEventListener('orientationchange', checkMobile);
  
    // Cleanup
    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener('orientationchange', checkMobile);
    };
  }, []);
  

  // Create a dedicated provider for public routes
  const PublicRouteProvider = ({ children }: { children: React.ReactNode }) => {
    const emptyContext: AppContextType = {
      ...initialState,
      loadTrees: async () => {},
      updateTree: () => {},
      deleteTree: async () => {},
      setSelectedTree: () => {},
      handleFilesUpload: async () => {},
      setIsUploadOpen: () => {},
      resetState: () => {},
      loadTreeDetails: async () => null,
      fetchSubscription: async () => {},
      getSupportEmail: async () => '',
    };
    
    return <AppContext.Provider value={emptyContext}>{children}</AppContext.Provider>;
  };

  // If it's a public route (except for public tree views), use the lightweight provider
  if (isPublicRoute && !location.pathname.startsWith('/trees/public')) {
    return <PublicRouteProvider>{children}</PublicRouteProvider>;
  }

  const [state, setState] = useState<AppState>(() => ({
    ...initialState,
    isMobile: window.innerWidth <= 768,
    setIsMobile: (isMobile: boolean) => setState(prev => ({ ...prev, isMobile }))
  }));

  const { getAccessTokenSilently } = useAuth0();
  const { toast } = useToast();
  const isInitializingRef = useRef(false);
  const subscriptionPassRef = useRef<string | null>(null);
  const imageLoadedRef = useRef<Set<string>>(new Set()); // Track which images have been loaded

  const configureClient = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers: Record<string, string> = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      if (subscriptionPassRef.current) {
        headers['X-Subscription-Pass'] = subscriptionPassRef.current;
      }

      client.setConfig({
        baseURL: config.apiBaseUrl,
        headers,
      });

      return true;
    } catch (error) {
      console.error('Error configuring client:', error);
      return false;
    }
  }, [getAccessTokenSilently]);

  const getSubscriptionDetails = useCallback(async () => {
    const now = Date.now();
    const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
  
    if (state.subscriptionCache && state.subscriptionCacheExpiry && now < state.subscriptionCacheExpiry) {
      return state.subscriptionCache;
    }
  
    try {
      await configureClient();
      const token = await getAccessTokenSilently();
      const response = await getApiSubscriptions({
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setState(prev => ({
        ...prev,
        subscriptionCache: response,
        subscriptionCacheExpiry: now + CACHE_DURATION
      }));
  
      return response;
    } catch (error) {
      console.error('Error fetching subscription:', error);
      return null;
    }
  }, [configureClient, getAccessTokenSilently, state.subscriptionCache, state.subscriptionCacheExpiry]);

  const getSupportEmail = useCallback(async () => {
    try {
      await fetchSubscription();
      await configureClient();
      const response = await getSupport();
      return response.data.email || '';
    } catch (error) {
      console.error('Error fetching support:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch support information',
        variant: 'destructive',
      });
      return '';
    }
  }, [configureClient, toast]);

  const addQA = useCallback(async (treeId: string, question: string) => {
    try {
      await configureClient();
      const response = await postQaByTreeId({
        path: { treeId },
        body: { question }
      });
      
      const newQA = response.data;
      
      setState(prev => ({
        ...prev,
        qaCache: {
          ...prev.qaCache,
          [treeId]: [...(prev.qaCache[treeId] || []), newQA],
        },
      }));

      return newQA;
    } catch (error) {
      console.error('Error adding QA:', error);
      toast({
        title: 'Error',
        description: 'Failed to add question',
        variant: 'destructive',
      });
      throw error;
    }
  }, [configureClient, toast]);

  const setIsChatMinimized = useCallback((isMinimized: boolean) => {
    setState(prev => ({ ...prev, isChatMinimized: isMinimized }));
  }
  , []);

  const deleteQA = useCallback(async (qaId: string, treeId: string) => {
    try {
      await configureClient();
      await deleteQaByQaId({ path: { qaId } });
      
      setState(prev => ({
        ...prev,
        qaCache: {
          ...prev.qaCache,
          [treeId]: prev.qaCache[treeId]?.filter(qa => qa.id !== qaId) || [],
        },
      }));
      
      toast({ 
        title: 'Success', 
        description: 'Question deleted successfully' 
      });
    } catch (error) {
      console.error('Error deleting QA:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete question',
        variant: 'destructive',
      });
      throw error;
    }
  }, [configureClient, toast]);

  const loadQAs = useCallback(async (treeId: string) => {
    try {
      await configureClient();
      const response = await getQaByTreeId({ path: { treeId } });
      const qas = response.data || [];
      
      setState(prev => ({
        ...prev,
        qaCache: { ...prev.qaCache, [treeId]: qas },
      }));

      return qas;
    } catch (error) {
      console.error('Error loading QAs:', error);
      toast({
        title: 'Error',
        description: 'Failed to load questions and answers',
        variant: 'destructive',
      });
      return [];
    }
  }, [configureClient, toast]);

  const fetchSubscription = useCallback(async () => {
    try {
      await configureClient(); // Ensure client is configured first
      const response = await getSubscriptionPass();
      if (response.data?.subscription_pass) {
        subscriptionPassRef.current = response.data.subscription_pass;
        setState(prev => ({
          ...prev,
          subscriptionPass: response.data.subscription_pass
        }));
      }
    } catch (error) {
      console.error('Error fetching subscription:', error);
    }
  }, [configureClient]); // Add configureClient to dependencies

  const loadImageUrl = useCallback(async (fileId: string) => {
    const cachedUrl = state.imageUrls[fileId];
    if (cachedUrl) return cachedUrl;
    
    // Skip if this image has already been loaded
    if (imageLoadedRef.current.has(fileId)) return state.imageUrls[fileId];
    
    try {
      await configureClient();
      const response = await getFilesByFileId({ path: { fileId } });
      const url = response.data.url;
      
      // Mark this image as loaded
      imageLoadedRef.current.add(fileId);
      
      setState(prev => ({
        ...prev,
        imageUrls: { ...prev.imageUrls, [fileId]: url },
      }));
      
      return url;
    } catch (error) {
      console.error('Error loading image:', error);
      return null;
    }
  }, [configureClient, state.imageUrls]);

  const loadTreeDetails = useCallback(async (treeId: string, forceRefresh = false) => {
    const cachedTree = state.treeDetailsCache[treeId];
    if (!forceRefresh && cachedTree) {
      if (!state.qaCache[treeId]) {
        // Load QAs if not cached but tree is cached
        await loadQAs(treeId);
      }
      return cachedTree;
    }
    
    try {
      await configureClient();
      const [treeResponse] = await Promise.all([
        getTreesByTreeId({ path: { treeId } }),
        loadQAs(treeId), // Load QAs alongside tree details
      ]);
      
      const treeDetails = treeResponse.data;
  
      // Load all images
      if (treeDetails.images?.length) {
        await Promise.all(
          treeDetails.images.map(image => 
            image.id && !imageLoadedRef.current.has(image.id) 
              ? loadImageUrl(image.id) 
              : null
          )
        );
      }
  
      setState(prev => ({
        ...prev,
        treeDetailsCache: { ...prev.treeDetailsCache, [treeId]: treeDetails },
      }));
  
      return treeDetails;
    } catch (error) {
      console.error('Error loading tree details:', error);
      return null;
    }
  }, [configureClient, loadImageUrl, loadQAs]);

  const loadTrees = useCallback(async () => {
    if (state.isLoggingOut) return;
    
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      await configureClient();
      const response = await getTrees();
      if (response.data) {
        const sorted = [...response.data].sort((a, b) => {
          return new Date(b.updatedAt || '').getTime() - new Date(a.updatedAt || '').getTime();
        });
        setState(prev => ({ ...prev, trees: sorted }));
        
        await Promise.all(sorted.map(tree => {
          if (tree.id && !state.treeDetailsCache[tree.id]) {
            return loadTreeDetails(tree.id);
          }
          return Promise.resolve();
        }));
      }
    } catch (error) {
      console.error('Error loading trees:', error);
      toast({
        title: 'Error',
        description: 'Failed to load trees',
        variant: 'destructive',
      });
    } finally {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, [configureClient, loadTreeDetails, toast, state.isLoggingOut]);

  const updateTree = useCallback(async (updatedTree: Tree) => {
    if (!updatedTree.id) return;
    try {
      await configureClient();
      const response = await putTreesByTreeId({
        path: { treeId: updatedTree.id },
        body: { isPublic: updatedTree.isPublic },
      });
      
      setState(prev => ({
        ...prev,
        trees: prev.trees.map(t => (t.id === updatedTree.id ? response.data : t)),
        selectedTree: prev.selectedTree?.id === updatedTree.id
          ? { ...prev.selectedTree, ...response.data }
          : prev.selectedTree,
      }));
      
      toast({ title: 'Success', description: 'Tree updated successfully' });
    } catch (error) {
      console.error('Error updating tree:', error);
      toast({
        title: 'Error',
        description: 'Failed to update tree',
        variant: 'destructive',
      });
    }
  }, [configureClient, toast]);

  const deleteTree = useCallback(async (treeId: string, onSuccess?: () => void) => {
    try {
      await configureClient();
      await deleteTreesByTreeId({ path: { treeId } });
      
      setState(prev => ({
        ...prev,
        trees: prev.trees.filter(t => t.id !== treeId),
        selectedTree: prev.selectedTree?.id === treeId ? null : prev.selectedTree,
        treeDetailsCache: { ...prev.treeDetailsCache, [treeId]: undefined as any },
      }));
      
      onSuccess?.();
      toast({ title: 'Success', description: 'Tree deleted successfully' });
    } catch (error) {
      console.error('Error deleting tree:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete tree',
        variant: 'destructive',
      });
    }
  }, [configureClient, toast]);

  const handleFilesUpload = useCallback(async (files: File[], onSuccess?: (treeId: string) => void) => {
    setState(prev => ({ ...prev, isUploading: true, uploadProgress: 0 }));
    try {
      await configureClient();
      const treeResponse = await postTrees({ body: {} });
      const treeId = treeResponse.data?.id;
      if (!treeId) throw new Error('Invalid tree response');

      for (const [index, file] of files.entries()) {
        const urlResponse = await getFilesUploadUrl({
          query: { filename: file.name, treeId },
        });
        
        await fetch(urlResponse.data.url, {
          method: 'PUT',
          body: file,
          headers: { 'Content-Type': file.type },
        });
        
        await postFilesCompleteUpload({ query: { fileId: urlResponse.data.fileId! } });
        
        if (urlResponse.data.fileId) {
          await loadImageUrl(urlResponse.data.fileId);
        }
        
        setState(prev => ({
          ...prev,
          uploadProgress: ((index + 1) / files.length) * 100,
        }));
      }

      const updatedDetails = await loadTreeDetails(treeId, true);
      if (updatedDetails) {
        setState(prev => ({
          ...prev,
          trees: [treeResponse.data, ...prev.trees],
          selectedTree: updatedDetails,
          treeDetailsCache: { ...prev.treeDetailsCache, [treeId]: updatedDetails },
          isUploading: false,
          uploadProgress: 100,
          isUploadOpen: false,
        }));
        
        onSuccess?.(treeId);
      }
      
      toast({ title: 'Success', description: 'Files uploaded successfully' });
    } catch (error) {
      console.error('Upload failed:', error);
      toast({
        title: 'Error',
        description: 'Failed to upload files',
        variant: 'destructive',
      });
    } finally {
      setState(prev => ({ ...prev, isUploading: false, uploadProgress: 0 }));
    }
  }, [configureClient, loadTreeDetails, loadImageUrl, toast]);

  useEffect(() => {
    if (isInitializingRef.current) return;
    
    const init = async () => {
      isInitializingRef.current = true;
      try {
        await configureClient();
        await fetchSubscription();
        await loadTrees();
        setState(prev => ({ ...prev, isInitialized: true }));
      } catch (error) {
        console.error('Error initializing app:', error);
        toast({
          title: 'Error',
          description: 'Failed to initialize application',
          variant: 'destructive',
        });
      } finally {
        isInitializingRef.current = false;
      }
    };

    init();
  }, [configureClient, fetchSubscription, loadTrees, toast]);

  const resetState = useCallback(() => {
    setState(prev => ({
      ...prev,
      isLoggingOut: true
    }));
    
    setTimeout(() => {
      setState(initialState);
    }, 100);
  }, []);

  // Export track function for component use
  const track = useCallback((event: string, properties?: Record<string, any>) => {
    posthog.capture(event, {
      ...properties,
      timestamp: new Date().toISOString()
    });
  }, []);


  const value: AppContextType = {
    ...state,
    loadTrees,
    updateTree,
    deleteTree,
    setSelectedTree: (tree: TreeDetailed | null) =>
      setState(prev => ({ ...prev, selectedTree: tree })),
    handleFilesUpload,
    setIsUploadOpen: (isOpen: boolean) =>
      setState(prev => ({ ...prev, isUploadOpen: isOpen })),
    resetState,
    loadQAs,
    addQA,
    deleteQA,
    loadTreeDetails,
    fetchSubscription,
    getSupportEmail,
    getSubscriptionDetails,
    setIsChatMinimized,
    track,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useApp() {
  const ctx = useContext(AppContext);
  if (!ctx) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return ctx;
}

export function useMobile() {
  const ctx = useContext(AppContext);
  if (!ctx) {
    throw new Error('useMobile must be used within an AppProvider');
  }
  return ctx.isMobile;
}