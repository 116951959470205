import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApp } from '../contexts/app-context';
import Navigation from './navigation';
import {
  Card, CardContent, CardHeader, CardTitle,
} from './ui/card';
import { Button } from './ui/button';
import { Alert, AlertTitle, AlertDescription } from "./ui/alert";
import {
  User, Mail, CreditCard, ExternalLink, 
  Crown, Calendar, Sparkles, PartyPopper, 
  AlertTriangle, ArrowRight
} from 'lucide-react';
import ReactConfetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import UploadDialog from './upload-dialog';


const AccountPage = () => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);

  const navigate = useNavigate();
  const { user, isLoading: authLoading, error: authError } = useAuth0();
  const { subscriptionPass, isInitialized, getSubscriptionDetails, track } = useApp();
  const [subscription, setSubscription] = React.useState(null);
  const [checkoutStatus, setCheckoutStatus] = React.useState('');
  const [showConfetti, setShowConfetti] = React.useState(false);

  const handleUpload = async (files: File[]) => {
    setIsUploading(true);
    // Simulate upload progress - replace with actual upload logic
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
        setIsUploading(false);
        setIsUploadDialogOpen(false);
        setUploadProgress(0);
      }
    }, 500);
  };

  React.useEffect(() => {
    const loadSubscription = async () => {
      const data = await getSubscriptionDetails();
      setSubscription(data);
    };

    if (subscriptionPass) {
      loadSubscription();
    }
  }, [subscriptionPass, getSubscriptionDetails]);

  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const success = query.get('success');
    const canceled = query.get('canceled');
  
    if (success === 'true') {
      setShowConfetti(true);
      setCheckoutStatus('success');
      track('subscription_completed', {
        status: 'success',
        user: {
          id: user?.sub,
          email: user?.email,
          is_new: !subscriptionPass // Identifies new subscribers
        },
        subscription_details: subscription?.data || {},
        acquisition: {
          source: 'stripe_redirect',
          path: '/account'
        }
      });
      setTimeout(() => setShowConfetti(false), 10000);
    } else if (canceled || success === 'false') {
      setCheckoutStatus(canceled ? 'canceled' : 'error');
      track('subscription_checkout_incomplete', {
        status: canceled ? 'canceled' : 'error',
        user_id: user?.sub
      });
    }
  
    window.history.replaceState({}, '', '/account');
  }, [user, track, subscription, subscriptionPass]);

  if (authLoading || !isInitialized) {
    return (
      <>
        <Navigation onUploadClick={() => setIsUploadDialogOpen(true)} />
        <LoadingSpinner />
      </>
    );
  }

  if (authError) {
    return (
      <>
        <Navigation onUploadClick={() => setIsUploadDialogOpen(true)} />
        <ErrorAlert message={authError.message} />
      </>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50">
      <Navigation onUploadClick={() => setIsUploadDialogOpen(true)} />  {/* Fix empty function */}
      {showConfetti && <Confetti />}
      
      <div className="max-w-4xl mx-auto px-4 py-8 space-y-6">
        <CheckoutAlert status={checkoutStatus} />
        <AccountDetailsCard user={user} />
        <SubscriptionCard 
          subscription={subscription} 
          subscriptionPass={subscriptionPass}
          onUpgrade={() => navigate('/signup')} 
        />
      </div>
  
      {/* Add UploadDialog here */}
      <UploadDialog
        isOpen={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}
        onUpload={handleUpload}
        uploadProgress={uploadProgress}
        isUploading={isUploading}
      />
    </div>
  );
};

// Reuse existing components from account.tsx
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-[calc(100vh-4rem)] bg-gradient-to-br from-emerald-50 to-teal-50">
    <div className="flex items-center gap-3">
      <div className="animate-spin h-8 w-8 border-b-2 border-emerald-700" />
      <p className="text-emerald-700 text-lg">Loading...</p>
    </div>
  </div>
);

// Keep existing components from account.tsx
const ErrorAlert = ({ message }) => (
  <div className="p-6 max-w-md mx-auto">
    <Alert variant="destructive">
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  </div>
);

const Confetti = () => (
  <ReactConfetti
    width={window.innerWidth}
    height={window.innerHeight}
    recycle={false}
    numberOfPieces={400}
    gravity={0.2}
  />
);

const CheckoutAlert = ({ status }) => {
  if (!status) return null;

  const alerts = {
    success: (
      <Alert className="bg-emerald-50 border-emerald-200 p-6">
        <div className="flex items-start gap-4">
          <PartyPopper className="h-8 w-8 text-emerald-600 flex-shrink-0 mt-1" />
          <div className="space-y-2">
            <AlertTitle className="text-2xl font-bold text-emerald-800">
              Welcome to the Pro Team!
            </AlertTitle>
            <AlertDescription className="text-lg space-y-2">
              <p className="text-emerald-700">
                Thank you for subscribing! Your account has been upgraded to Pro status.
              </p>
              <p className="text-emerald-600">
                You now have access to all premium features, including unlimited tree analysis,
                priority support, and advanced analytics.
              </p>
            </AlertDescription>
          </div>
        </div>
      </Alert>
    ),
    error: (
      <Alert variant="destructive" className="p-6">
        <div className="flex items-start gap-4">
          <AlertTriangle className="h-8 w-8 flex-shrink-0 mt-1" />
          <div className="space-y-2">
            <AlertTitle className="text-2xl font-bold">
              Something went wrong
            </AlertTitle>
            <AlertDescription className="text-lg">
              We couldn't process your subscription. Please try again or contact our support team for assistance.
            </AlertDescription>
          </div>
        </div>
      </Alert>
    ),
    canceled: (
      <Alert className="bg-orange-50 border-orange-200 p-6">
        <AlertTitle className="text-xl mb-2">No Changes Made</AlertTitle>
        <AlertDescription className="text-lg">
          Your subscription status remains unchanged. Feel free to upgrade whenever you're ready!
        </AlertDescription>
      </Alert>
    )
  };

  return alerts[status] || null;
};

const AccountDetailsCard = ({ user }) => (
  <Card className="border-emerald-100">
    <CardHeader>
      <div className="flex items-center justify-between">
        <CardTitle className="text-2xl text-emerald-800 flex items-center gap-3">
          <User className="h-6 w-6" />
          Account Details
        </CardTitle>
        {user?.email_verified && (
          <span className="px-3 py-1 bg-emerald-100 text-emerald-700 rounded-full text-sm">
            Verified
          </span>
        )}
      </div>
    </CardHeader>
    <CardContent className="grid md:grid-cols-2 gap-4">
      <div className="flex items-center gap-3 p-4 bg-emerald-50 rounded-lg">
        <User className="h-5 w-5 text-emerald-600" />
        <span className="font-medium">{user?.name}</span>
      </div>
      <div className="flex items-center gap-3 p-4 bg-emerald-50 rounded-lg">
        <Mail className="h-5 w-5 text-emerald-600" />
        <span className="font-medium">{user?.email}</span>
      </div>
    </CardContent>
  </Card>
);

const SubscriptionCard = ({ subscription, subscriptionPass, onUpgrade }) => {
  const formatDate = (date) => 
    date ? new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }) : 'N/A';

  const statusColors = {
    active: 'bg-green-100 text-green-700',
    trialing: 'bg-blue-100 text-blue-700',
    past_due: 'bg-yellow-100 text-yellow-700',
    canceled: 'bg-red-100 text-red-700',
  };

  return (
    <Card className="border-emerald-100">
      <CardHeader>
        <CardTitle className="text-2xl text-emerald-800 flex items-center justify-between">
          <div className="flex items-center gap-3">
            {subscriptionPass ? 
              <Crown className="h-6 w-6 text-yellow-500" /> : 
              <CreditCard className="h-6 w-6" />
            }
            {subscriptionPass ? 'Pro Subscription' : 'Free Plan'}
          </div>
          {subscription?.data?.status && (
            <span className={`px-3 py-1 rounded-full text-sm font-medium ${
              statusColors[subscription.data.status] || 'bg-gray-100'
            }`}>
              {subscription.data.status}
            </span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        {subscriptionPass ? (
          <div className="space-y-6">
            {subscription?.data?.currentPeriodEnd && (
              <div className="p-4 bg-emerald-50 rounded-lg">
                <div className="flex items-center gap-2 text-emerald-700">
                  <Calendar className="h-5 w-5" />
                  <span>Next payment: {formatDate(subscription.data.currentPeriodEnd)}</span>
                </div>
              </div>
            )}
            <ProBenefits />
            <Button
              onClick={() => window.location.href = subscription?.data?.portalUrl || ''}
              variant="outline"
              className="w-full h-12"
            >
              <CreditCard className="h-5 w-5 mr-2" />
              Manage Subscription
              <ExternalLink className="h-4 w-4 ml-2" />
            </Button>
          </div>
        ) : (
          <div className="p-6 bg-gradient-to-r from-emerald-50 to-teal-50 rounded-xl">
            <h3 className="text-xl text-emerald-800 mb-6 font-medium">Upgrade to Pro</h3>
            <ProBenefits />
            <Button 
              onClick={onUpgrade}
              className="w-full h-12 bg-gradient-to-r from-emerald-500 to-teal-500 hover:from-emerald-600 hover:to-teal-600"
            >
              Continue to Pro
              <ArrowRight className="h-5 w-5 ml-2" />
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const ProBenefits = () => (
  <div className="space-y-4 mb-6">
    <div className="flex items-center gap-3 mb-6">
      <Sparkles className="h-6 w-6 text-yellow-500" />
      <span className="font-medium text-xl text-emerald-800">Pro Benefits</span>
    </div>
    {['Unlimited tree analysis', 'Priority support', 'Advanced analytics'].map((benefit) => (
      <div key={benefit} className="flex items-center gap-3 text-emerald-700">
        <div className="h-2 w-2 rounded-full bg-emerald-500" />
        <span>{benefit}</span>
      </div>
    ))}
  </div>
);

export default AccountPage;