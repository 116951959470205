import React from 'react';
import Navigation from '../components/navigation';
import TreeList from '../components/treelist';
import UploadDialog from '../components/upload-dialog';
import { useApp } from '../contexts/app-context';
import { useMobile } from '../contexts/app-context';

const DashboardPage: React.FC = () => {
  const { 
    isUploadOpen, 
    setIsUploadOpen, 
    isUploading, 
    uploadProgress, 
    handleFilesUpload 
  } = useApp();
  const isMobile = useMobile();

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-emerald-50 to-teal-50">
      <Navigation onUploadClick={() => setIsUploadOpen(true)} />
  
      <div className={`flex-1 w-full max-w-7xl mx-auto ${isMobile ? 'px-3' : 'px-4'} py-6`}>
        <div className={`h-full ${isMobile ? 'mt-2' : 'mt-4'}`}>
          <TreeList />
        </div>
      </div>

      <UploadDialog
        isOpen={isUploadOpen}
        onClose={() => {
          if (!isUploading) {
            setIsUploadOpen(false);
          }
        }}
        onUpload={handleFilesUpload}
        uploadProgress={uploadProgress}
        isUploading={isUploading}
      />
    </div>
  );
};

export default DashboardPage;