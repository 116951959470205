import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useApp } from '../contexts/app-context';
import { Card, CardContent } from './ui/card';
import { TreePine, CheckCircle2, Loader2 } from 'lucide-react';
import { useToast } from '../hooks/use-toast';
import AuthDialog from './auth-dialog';
import { Button } from './ui/button';
import { postApiAuthSignup, client } from '../client/sdk.gen';
import config from '../config';

const SteppedSignup = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { subscriptionPass, fetchSubscription } = useApp();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isAuthOpen, setIsAuthOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleStartTrial = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      client.setConfig({
        baseURL: config.apiBaseUrl,
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const response = await postApiAuthSignup();
      if (response.data?.checkoutUrl) {
        window.open(response.data.checkoutUrl, '_blank');
      } else {
        toast({
          title: "Error",
          description: "Could not start trial. Please try again.",
          variant: "destructive",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to start trial. Please try again.",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (isAuthenticated && subscriptionPass) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, subscriptionPass, navigate]);

  if (!isAuthenticated) {
    return (
      <div className="max-w-md mx-auto p-6 space-y-6">
        <div className="text-center space-y-2">
          <TreePine className="h-12 w-12 mx-auto text-emerald-600" />
          <h2 className="text-2xl font-bold text-emerald-800">Get Started with TreeID</h2>
          <p className="text-emerald-600">Create your account to begin your free trial</p>
        </div>

        <Card>
          <CardContent className="p-6 space-y-4">
            {['Unlimited tree identifications', '7-day free trial', 'Cancel anytime'].map((text) => (
              <div key={text} className="flex items-center gap-2 text-emerald-700">
                <CheckCircle2 className="h-5 w-5 text-emerald-600" />
                <span>{text}</span>
              </div>
            ))}
            
            <Button 
              onClick={() => setIsAuthOpen(true)}
              className="w-full bg-gradient-to-r from-emerald-500 to-teal-500 hover:from-emerald-600 hover:to-teal-600 text-white"
              size="lg"
            >
              <TreePine className="mr-2 h-5 w-5" />
              Login
            </Button>
          </CardContent>
        </Card>

        <AuthDialog open={isAuthOpen} onOpenChange={setIsAuthOpen} />
      </div>
    );
  }

  if (!subscriptionPass) {
    return (
      <div className="max-w-xl mx-auto p-6 space-y-6">
        <div className="text-center space-y-2">
          <TreePine className="h-12 w-12 mx-auto text-emerald-600" />
          <h2 className="text-2xl font-bold text-emerald-800">Start Your Free Trial</h2>
          <p className="text-emerald-600">7 days of full access, cancel anytime</p>
        </div>

        <div className="bg-gradient-to-br from-white to-emerald-50 rounded-xl shadow-lg p-8 space-y-6">
          <div className="bg-emerald-50 p-4 rounded-lg">
            <p className="font-medium text-emerald-800">Signed in as</p>
            <p className="text-emerald-600">{user?.email}</p>
          </div>

          <Button 
            onClick={handleStartTrial}
            disabled={isLoading}
            className="w-full h-14 text-lg bg-gradient-to-r from-emerald-500 to-emerald-600 text-white"
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                Starting Trial...
              </>
            ) : (
              <>
                <TreePine className="mr-2 h-5 w-5" />
                Start Free Trial
              </>
            )}
          </Button>

          <p className="text-sm text-center text-emerald-600">
            No credit card required
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default SteppedSignup;