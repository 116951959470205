import React, { useEffect } from 'react';
import { 
  TreePine, 
  Sparkles,
  Share2,
  FileSpreadsheet,
  ShieldCheck,
  ArrowRight,
  Check
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from '../components/ui/button';
import SharedNav from '../components/landing-nav';
import { useMobile } from '../contexts/app-context';

const features = [
  {
    icon: TreePine,
    title: "Instant Tree Analysis",
    description: "Get accurate wood species identification in seconds"
  },
  {
    icon: Share2,
    title: "Verification Links",
    description: "Share professional verification reports with clients"
  },
  {
    icon: FileSpreadsheet,
    title: "Detailed Reports",
    description: "Access comprehensive analysis with confidence scores"
  },
  {
    icon: ShieldCheck,
    title: "Priority Support",
    description: "Get expert help when you need it most"
  }
];

export default function PricingPage() {
  const isMobile = useMobile();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-emerald-50 via-white to-emerald-50">
      <SharedNav />

      <main className={`pt-${isMobile ? '28' : '36'}`}>
        <div className={`text-center max-w-3xl mx-auto mb-16 ${isMobile ? 'px-4' : 'px-6'}`}>
          <h1 className={`${isMobile ? 'text-4xl' : 'text-5xl'} font-bold text-emerald-900 mb-6`}>
            Professional Tree Analysis
            <br />
            <span className="text-emerald-600">Made Simple</span>
          </h1>
          <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-emerald-600 mb-8`}>
            Get instant, accurate wood species identification with our advanced AI technology.
          </p>
          
          <div className={`inline-flex items-center gap-2 bg-emerald-50 text-emerald-700 ${isMobile ? 'px-3 py-2' : 'px-4 py-2'} rounded-full font-medium mb-8`}>
            <Sparkles className="h-5 w-5" />
            Start with a 7-day free trial
          </div>
        </div>

        <div className={`max-w-lg mx-auto mb-16 ${isMobile ? 'px-4' : 'px-6'}`}>
          <Card className="border-2 border-emerald-200 shadow-lg">
            <CardHeader className="text-center pb-4">
              <CardTitle className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-bold text-emerald-900`}>
                TreeID Pro
              </CardTitle>
              <CardDescription className="text-emerald-600">
                Everything you need for professional tree analysis
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="text-center">
                <div className="flex items-baseline justify-center gap-2">
                  <span className={`${isMobile ? 'text-4xl' : 'text-5xl'} font-bold text-emerald-900`}>$7</span>
                  <span className={`${isMobile ? 'text-lg' : 'text-xl'} text-emerald-600`}>/month</span>
                </div>
              </div>
              
              <div className="space-y-3">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-start gap-3">
                    <div className="flex-shrink-0 mt-1">
                      <Check className="h-5 w-5 text-emerald-500" />
                    </div>
                    <div>
                      <h3 className="font-medium text-emerald-900">{feature.title}</h3>
                      <p className={`${isMobile ? 'text-sm' : 'text-base'} text-emerald-600`}>
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
            <CardFooter className="flex justify-center">
              <Button
                onClick={() => {
                  window.location.href = '/signup';
                }}
                className={`bg-gradient-to-r from-emerald-500 to-emerald-600 text-white 
                  hover:from-emerald-600 hover:to-emerald-700 
                  ${isMobile ? 'w-full text-lg py-6' : 'w-auto text-xl py-8'} 
                  px-12 rounded-2xl shadow-md hover:shadow-lg transition-all`}
              >
                Start Free Trial
                <ArrowRight className={`ml-2 ${isMobile ? 'h-4 w-4' : 'h-5 w-5'}`} />
              </Button>
            </CardFooter>
          </Card>
        </div>

        <div className={`text-center max-w-2xl mx-auto ${isMobile ? 'px-4' : 'px-6'} pb-16`}>
          <h2 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-semibold text-emerald-900 mb-4`}>
            Trusted by Professional Arborists
          </h2>
          <p className={`text-emerald-600 ${isMobile ? 'text-base' : 'text-lg'}`}>
            Join a growing community of tree care professionals who use TreeID for accurate, 
            reliable wood species identification.
          </p>
        </div>
      </main>
    </div>
  );
}