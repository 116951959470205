import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Progress } from './ui/progress';
import {
  TreePine,
  Leaf,
  AlertCircle,
  ArrowRight,
  Info,
  Calendar,
  GitBranch,
  ArrowLeft,
  Share2,
  Camera
} from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';
import { useToast } from '../hooks/use-toast';
import type { TreeDetailed } from '../client/types.gen';
import { getPublicTree, getPublicFile } from '../contexts/public-client';
import { copyToClipboard } from './toast-provider';
import TreeGallery from './tree-gallery';
import { useMobile } from '../contexts/app-context';
import { cn } from '../lib/utils';

const PublicTreeView = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { treeId } = useParams<{ treeId: string }>();
  const [tree, setTree] = useState<TreeDetailed | null>(null);
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSharing, setIsSharing] = useState(false);
  const isMobile = useMobile();

  const CTASection = () => (
    <div className={cn(
      "bg-gradient-to-br from-emerald-500 to-teal-600 rounded-xl",
      "text-white shadow-md overflow-hidden",
      isMobile ? "mt-4" : "mt-6" // Reduced margin on mobile
    )}>
      <div className={cn(
        "p-4",
        !isMobile && "p-6" // Less padding on mobile
      )}>
        {isMobile ? (
          // Mobile layout - more compact
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Camera className="h-4 w-4 text-white/90" />
                <h3 className="font-medium text-base">
                  Want to identify your trees?
                </h3>
              </div>
              <Button
                onClick={() => navigate('/signup')}
                className="bg-white text-emerald-600 hover:bg-emerald-50 px-3 py-1.5"
                size="sm"
              >
                Try Free
                <ArrowRight className="ml-1 h-3 w-3" />
              </Button>
            </div>
            <div className="flex items-center gap-3 text-xs text-white/80">
              <div className="flex items-center gap-1">
                <TreePine className="h-3 w-3" />
                100,000+ Species
              </div>
              <div className="flex items-center gap-1">
                <AlertCircle className="h-3 w-3" />
                7-Day Trial
              </div>
            </div>
          </div>
        ) : (
          // Desktop layout - remains the same
          <div className="flex flex-col md:flex-row items-center justify-between gap-6">
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <div className="bg-white/20 p-2 rounded-lg">
                  <Camera className="h-6 w-6" />
                </div>
                <h3 className="text-2xl font-bold">
                  Want to Identify Your Own Trees?
                </h3>
              </div>
              <p className="text-white/90">
                Get instant, accurate tree identification for any species. Start your free trial today!
              </p>
            </div>
            <Button
              onClick={() => navigate('/signup')}
              className="shrink-0 bg-white text-emerald-600 hover:bg-emerald-50
                       shadow-md hover:shadow-lg transition-all py-6 px-8"
            >
              Try TreeID Free
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    const loadPublicTree = async () => {
      if (!treeId) return;
      
      try {
        const response = await getPublicTree(treeId);
        setTree(response.data);

        // Load all image URLs in parallel
        if (response.data.images?.length) {
          const urls: Record<string, string> = {};
          await Promise.all(
            response.data.images.map(async (image) => {
              if (image.id) {
                const imageResponse = await getPublicFile(image.id);
                urls[image.id] = imageResponse.data.url;
              }
            })
          );
          setImageUrls(urls);
        }
      } catch (err) {
        setError('This tree is not publicly available');
        console.error('Error loading public tree:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadPublicTree();
  }, [treeId]);

  const handleShareClick = async () => {
    if (!treeId) return;
    setIsSharing(true);
    try {
      await copyToClipboard(
        `${window.location.origin}/trees/public/${treeId}`,
        (options) => toast({
          ...options,
          className: "bg-white border-l-4 border-l-emerald-500 shadow-md",
          description: "Link copied to clipboard",
          duration: 2000
        })
      );
    } finally {
      setIsSharing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-emerald-50 to-teal-50">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-emerald-500 border-t-transparent"></div>
      </div>
    );
  }

  if (error || !tree) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-emerald-50 to-teal-50">
        <div className="text-center space-y-4">
          <div className="bg-emerald-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto">
            <TreePine className="h-8 w-8 text-emerald-600" />
          </div>
          <p className="text-lg text-gray-600">{error || 'Tree not found'}</p>
          <Button
            variant="outline"
            onClick={() => navigate('/')}
            className="text-emerald-600 hover:text-emerald-700"
          >
            Return Home
          </Button>
        </div>
      </div>
    );
  }

  const analysis = tree.analyses;

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50">
      <nav className="border-b bg-white/80 backdrop-blur-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <Button
              variant="ghost"
              size="sm"
              className="text-emerald-600 hover:text-emerald-700"
              onClick={() => navigate('/')}
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Back to Home
            </Button>
            <div className="flex items-center gap-2">
              <div className="bg-gradient-to-br from-emerald-400 to-emerald-600 p-1.5 rounded-lg">
                <TreePine className="h-5 w-5 text-white" />
              </div>
              <span className="font-semibold text-emerald-900">TreeID</span>
            </div>
          </div>
        </div>
      </nav>

      <div className="w-full max-w-7xl mx-auto px-4 py-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Tree Gallery Section */}
          <div className="space-y-4">
            {tree.images && (
              <TreeGallery 
                images={tree.images}
                imageUrls={imageUrls}
              />
            )}
            {/* {isMobile && <CTASection />} */}
          </div>

          <div className="space-y-6">
            {/* Analysis Card */}
            <Card className={cn(
              "w-full backdrop-blur-sm",
              isMobile ? "bg-white/90" : "bg-white/80"
            )}>
            <CardHeader className="border-b border-gray-100">
              <div className="flex items-center justify-between">
                <CardTitle className={cn(
                  "text-emerald-800 flex items-center gap-2",
                  isMobile ? "text-xl" : "text-2xl"
                )}>
                  <Leaf className={cn(
                    "text-emerald-600",
                    isMobile ? "h-5 w-5" : "h-6 w-6"
                  )} />
                  Tree Analysis Results
                </CardTitle>
                {analysis?.analysis_date && (
                  <div className="flex items-center gap-2 text-sm text-gray-500">
                    <Calendar className="h-4 w-4" />
                    {new Date(analysis.analysis_date).toLocaleDateString()}
                  </div>
                )}
              </div>
            </CardHeader>
            <CardContent className={cn(
              "space-y-6",
              isMobile ? "p-4" : "p-6"
            )}>
              {!analysis?.message ? (
                <Alert className="bg-emerald-50 border-emerald-200">
                  <AlertCircle className="h-5 w-5 text-emerald-600" />
                  <AlertTitle className="text-emerald-800 text-lg">No Analysis Available</AlertTitle>
                  <AlertDescription className="text-emerald-600">
                    This tree hasn't been analyzed yet.
                  </AlertDescription>
                </Alert>
              ) : (
                <>
                  {/* Primary Result */}
                  <div className="bg-gradient-to-br from-emerald-50 via-emerald-100/50 to-emerald-50 rounded-xl p-6 shadow-sm">
                    <div className="space-y-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="text-sm font-medium text-emerald-600">Identified Species</h3>
                          <p className="text-3xl font-bold text-emerald-900 mt-1">{analysis.tree_type}</p>
                        </div>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger onClick={handleShareClick}>
                              <div className={cn(
                                "bg-white p-3 rounded-full shadow-sm cursor-pointer hover:bg-gray-50 transition-colors",
                                isSharing && "animate-pulse"
                              )}>
                                <GitBranch className="h-6 w-6 text-emerald-600" />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Share Tree Analysis</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm font-medium text-emerald-700">
                            Confidence Score
                          </span>
                          <span className="text-sm font-bold text-emerald-800">
                            {(analysis.confidence * 100).toFixed(1)}%
                          </span>
                        </div>
                        <Progress 
                          value={analysis.confidence * 100} 
                          className="h-2 bg-emerald-100"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Analysis Details */}
                  <div className="bg-white rounded-xl p-6 border border-gray-100">
                    <div className="flex items-start gap-4">
                      <div className="bg-emerald-100 p-2 rounded-lg">
                        <Info className="h-5 w-5 text-emerald-600" />
                      </div>
                      <div className="space-y-2 flex-1">
                        <h3 className="text-lg font-medium text-emerald-800">Expert Analysis</h3>
                        <p className="text-gray-600 leading-relaxed whitespace-pre-wrap">
                          {analysis.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Similar Species */}
                  {analysis.matches && analysis.matches.length > 0 && (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-medium text-emerald-800 flex items-center gap-2">
                          <TreePine className="h-5 w-5 text-emerald-600" />
                          Similar Species
                        </h3>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Info className="h-4 w-4 text-emerald-400" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Other possible tree species matches</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      
                      <div className="grid gap-4 md:grid-cols-2">
                        {analysis.matches.map((match, index) => (
                          <div 
                            key={index}
                            className="group relative bg-white rounded-xl border border-gray-100 hover:border-emerald-200 transition-all duration-300 hover:shadow-lg overflow-hidden"
                          >
                            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-emerald-200 to-emerald-300 opacity-50" />
                            <div className="p-4 space-y-3">
                              <div className="flex justify-between items-start">
                                <div className="space-y-1">
                                  <span className="text-lg font-semibold text-gray-900 group-hover:text-emerald-700 transition-colors">
                                    {match.tree_type}
                                  </span>
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm text-emerald-600 font-medium">
                                      Match Confidence
                                    </span>
                                  </div>
                                </div>
                                <div className="bg-emerald-50 px-3 py-1 rounded-full">
                                  <span className="text-sm font-bold text-emerald-600">
                                    {(match.confidence * 100).toFixed(1)}%
                                  </span>
                                </div>
                              </div>
                              
                              <div className="space-y-2">
                                <Progress 
                                  value={match.confidence * 100} 
                                  className="h-2 bg-emerald-100"
                                />
                                <div className="flex justify-between items-center text-xs text-gray-500">
                                  <span>0%</span>
                                  <span>50%</span>
                                  <span>100%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <Button
                    onClick={handleShareClick}
                    className="w-full bg-gradient-to-r from-blue-500 to-blue-600 
                             hover:from-blue-600 hover:to-blue-700 text-white"
                    disabled={isSharing}
                  >
                    <Share2 className={`h-5 w-5 mr-2 ${isSharing ? 'animate-pulse' : ''}`} />
                    {isSharing ? 'Sharing...' : 'Share Analysis'}
                  </Button>
                </>
              )}
            </CardContent>
            </Card>
          
          {/* CTA Section - only show on desktop */}
          {!isMobile && <CTASection />}
        </div>
      </div>

      {/* CTA Section - only show on mobile */}
      {isMobile && <div className="mt-6">
        <CTASection />
      </div>}
      </div>
    </div>
  );
};

export default PublicTreeView;