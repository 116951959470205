import React from 'react';
import { useApp } from '../contexts/app-context';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { ScrollArea } from './ui/scroll-area';
import { Loader2, Send, Leaf, Trash2, Minus } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "./ui/alert-dialog";
import { cn } from '../lib/utils';

const TreeChatDialog = ({ className }: { className?: string }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    isMobile,
    isChatMinimized,
    setIsChatMinimized,
    selectedTree,
    loadQAs,
    addQA,
    deleteQA
  } = useApp();

  const [qas, setQas] = React.useState<any[]>([]);
  const [newQuestion, setNewQuestion] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchQAs = async () => {
      if (!selectedTree?.id) return;
      setIsLoading(true);
      try {
        const loaded = await loadQAs(selectedTree.id);
        setQas(loaded.filter((qa: any) => qa.id && qa.question));
      } finally {
        setIsLoading(false);
      }
    };
    if (selectedTree?.id) fetchQAs();
  }, [selectedTree?.id, loadQAs]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' });
    }
  };

  const handleInputFocus = () => {
    setTimeout(() => {
      scrollToBottom();
      inputRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newQuestion.trim() || !selectedTree?.id) return;
    setIsSubmitting(true);
    try {
      const qa = await addQA(selectedTree.id, newQuestion.trim());
      if (qa?.id) {
        setQas(prev => [...prev, qa]);
        setNewQuestion('');
        setTimeout(scrollToBottom, 100);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (qaId: string) => {
    if (!selectedTree?.id) return;
    setDeletingId(qaId);
    try {
      await deleteQA(qaId, selectedTree.id);
      setQas(prev => prev.filter(qa => qa.id !== qaId));
    } finally {
      setDeletingId(null);
    }
  };

  const handleDeleteAll = async () => {
    if (!selectedTree?.id) return;
    setIsLoading(true);
    try {
      await Promise.all(qas.map(qa => deleteQA(qa.id, selectedTree.id)));
      setQas([]);
    } finally {
      setIsLoading(false);
    }
  };

  if (!selectedTree?.id) return null;

  const mobileTextClasses = "text-2xl leading-relaxed whitespace-pre-wrap break-words";
  const desktopTextClasses = "text-base leading-snug whitespace-pre-wrap break-words";

  const renderQABlock = (text: string, colorClasses: string) => (
    <p
      className={cn(
        "rounded-2xl rounded-tl-sm bg-gradient-to-br p-4 mb-4",
        isMobile ? mobileTextClasses : desktopTextClasses,
        colorClasses
      )}
    >
      {text}
    </p>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex h-full items-center justify-center">
          <Loader2 className="h-5 w-5 animate-spin text-emerald-500" />
          <span className="ml-2 text-emerald-600">Loading...</span>
        </div>
      );
    }

    if (qas.length === 0) {
      return (
        <div className="py-4 px-2">
          <div className="flex gap-3">
            <div className="mt-1 flex h-8 w-8 items-center justify-center">
              <div className="rounded-lg bg-gray-100 p-1.5">
                <Leaf className="h-4 w-4 text-gray-600" />
              </div>
            </div>
            <div className="flex-1">
              {renderQABlock(
                `Hi, I am a ${selectedTree.analyses?.result || 'tree'}! What questions do you have?`,
                "from-gray-50 to-gray-100/30 text-gray-800"
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-8 overflow-y-auto h-full">
        {qas.map(qa => (
          <div key={qa.id} className="px-2">
            <div className="mb-2 flex gap-3">
              <div className="flex h-8 w-8 shrink-0 items-center justify-center mt-1">
                <div className="rounded-lg bg-emerald-100 p-1.5">
                  <Leaf className="h-4 w-4 text-emerald-600" />
                </div>
              </div>
              <div className="flex-1">
                <div className="group flex items-start gap-2">
                  {renderQABlock(qa.question, "from-emerald-50 to-emerald-100/30 text-emerald-900")}
                  <Button
                    size="icon"
                    variant="ghost"
                    className={cn(
                      "h-8 w-8 shrink-0 hover:bg-red-100",
                      isMobile ? "opacity-100" : "opacity-0 group-hover:opacity-100"
                    )}
                    onClick={() => handleDelete(qa.id)}
                    disabled={deletingId === qa.id}
                  >
                    {deletingId === qa.id ? (
                      <Loader2 className="h-4 w-4 animate-spin text-red-600" />
                    ) : (
                      <Trash2 className="h-4 w-4 text-red-600" />
                    )}
                  </Button>
                </div>
              </div>
            </div>

            {qa.answer && (
              <div className="ml-8 flex gap-3">
                <div className="flex h-8 w-8 shrink-0 items-center justify-center mt-1">
                  <div className="rounded-lg bg-gray-100 p-1.5">
                    <Leaf className="h-4 w-4 text-gray-600" />
                  </div>
                </div>
                <div className="flex-1">
                  {renderQABlock(qa.answer, "from-gray-50 to-gray-100/30 text-gray-800")}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={cn(
        "fixed flex flex-col bg-white shadow-lg transition-all duration-200",
        isMobile
          ? isChatMinimized
            ? "bottom-0 right-0 h-[60px] w-full border-t border-emerald-200"
            : "inset-0"
          : isChatMinimized
            ? "bottom-0 right-0 md:bottom-4 md:right-4 h-[60px] w-[400px] rounded-lg border border-emerald-200"
            : "bottom-0 right-0 md:bottom-4 md:right-4 h-[600px] w-[400px] rounded-lg border border-emerald-200",
        className
      )}
      style={{ zIndex: 50 }}
    >
      {/* Header */}
      <div
        className={cn(
          "flex items-center justify-between px-4 py-2 border-b border-emerald-100 bg-gradient-to-r from-emerald-50 to-white",
          isMobile && "sticky top-0 z-10"
        )}
      >
        <div className="flex items-center gap-2">
          <div className="rounded-lg bg-emerald-100 p-1.5">
            <Leaf className="h-4 w-4 text-emerald-600" />
          </div>
          <h2 className="text-sm font-medium text-emerald-800">Chat with Your Tree</h2>
        </div>

        <div className="flex items-center gap-2">
          {/* Larger Minimize Button */}
          <Button
            variant="ghost"
            className="flex items-center text-emerald-600 hover:bg-emerald-100 h-10 px-3"
            onClick={() => setIsChatMinimized(!isChatMinimized)}
          >
            <Minus className="h-6 w-6" />
            {!isChatMinimized && <span className="text-base">Minimize</span>}
          </Button>

          {/* Clear All (Desktop only if not minimized) */}
          {!isChatMinimized && !isMobile && qas.length > 0 && (
            <AlertDialog>
  <AlertDialogTrigger asChild>
    <Button
      size="sm"
      variant="ghost"
      className="hidden md:flex h-8 px-2 text-red-600 hover:bg-red-100 hover:text-red-700"
    >
      <Trash2 className="h-4 w-4 mr-1" />
      Clear All
    </Button>
  </AlertDialogTrigger>
  <AlertDialogContent
    className="max-w-md border border-emerald-300 shadow-2xl bg-white text-emerald-900 p-6"
  >
    <AlertDialogHeader>
      <AlertDialogTitle>Clear all messages?</AlertDialogTitle>
      <AlertDialogDescription>
        This action cannot be undone.
      </AlertDialogDescription>
    </AlertDialogHeader>
    <AlertDialogFooter>
      <AlertDialogCancel>Cancel</AlertDialogCancel>
      <AlertDialogAction
        className="bg-red-600 hover:bg-red-700 text-white"
        onClick={handleDeleteAll}
      >
        Delete All
      </AlertDialogAction>
    </AlertDialogFooter>
  </AlertDialogContent>
</AlertDialog>
          )}
        </div>
      </div>

      {/* Chat Content */}
      {!isChatMinimized && (
        <>
          <ScrollArea ref={scrollRef} className="flex-1" onClick={scrollToBottom}>
            {renderContent()}
          </ScrollArea>

          {/* Input */}
          <div className={cn("border-t border-emerald-100 bg-white p-4", isMobile && "sticky bottom-0")}>
            <form onSubmit={handleSubmit} className="flex gap-2">
              <Input
                ref={inputRef}
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Ask about your tree..."
                className={cn(
                  "flex-1 border-emerald-200 focus:ring-emerald-500 focus-visible:ring-emerald-500",
                  isMobile ? "text-xl h-14 leading-relaxed" : "text-sm h-10"
                )}
                disabled={isSubmitting}
              />
              <Button
                type="submit"
                disabled={isSubmitting || !newQuestion.trim()}
                size="icon"
                className={cn(
                  "shrink-0 rounded-lg bg-emerald-600 hover:bg-emerald-700",
                  isMobile ? "h-14 w-14" : "h-10 w-10"
                )}
              >
                {isSubmitting ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <Send className="h-4 w-4" />
                )}
              </Button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default TreeChatDialog;