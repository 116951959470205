import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, TreePine } from 'lucide-react';
import { Button } from './ui/button';
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetClose,
} from "./ui/sheet";
import Logo from './logo';
import AuthDialog from './auth-dialog';
import { useApp } from '../contexts/app-context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const navItems = [
  { name: 'Story', href: '/about' },
  { name: 'Privacy', href: '/privacy' },
  { name: 'Pricing', href: '/pricing' },
] as const;

interface SharedNavProps {
  transparent?: boolean;
}

export const SharedNav: React.FC<SharedNavProps> = ({ transparent = false }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const { fetchSubscription, subscriptionPass } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthenticationState = async () => {
      if (isAuthenticated && location.pathname === '/') {
        await fetchSubscription();
        navigate(subscriptionPass ? '/dashboard' : '/signup');
      }
    };

    handleAuthenticationState();
  }, [isAuthenticated, location.pathname, fetchSubscription, navigate, subscriptionPass]);

  const AuthButton = ({ className }: { className?: string }) => {
    if (isAuthenticated) {
      return (
        <Button
          onClick={() => window.location.href = '/dashboard'}
          className={`bg-gradient-to-r from-emerald-500 to-teal-500 
                      hover:from-emerald-600 hover:to-teal-600 text-white p-6 text-lg ${className}`}
          size="lg"
        >
          <TreePine className="mr-3 h-6 w-6 text-white" />
          <span className="text-white">Dashboard</span>
        </Button>
      );
    }
    
    return <AuthDialog />;
  };

  return (
    <nav className={`fixed w-full z-50 ${transparent ? 'bg-transparent' : 'bg-white/90 backdrop-blur-lg border-b border-emerald-100 shadow-sm'}`}>
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <Logo href={isAuthenticated ? '/dashboard' : '/'} />

          <div className="hidden md:flex items-center gap-8">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`text-lg ${location.pathname === item.href 
                  ? 'text-emerald-800 font-medium' 
                  : 'text-emerald-600 hover:text-emerald-800'}`}
              >
                {item.name}
              </Link>
            ))}
            <AuthButton className="text-lg text-emerald-600 hover:text-emerald-800" />
          </div>

          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="ghost"
                className="md:hidden"
                size="icon"
              >
                <Menu className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent side="right" className="w-full sm:w-80">
              <div className="flex flex-col gap-6 pt-8">
                {navItems.map((item) => (
                  <SheetClose asChild key={item.name}>
                    <Link
                      to={item.href}
                      className={`text-xl ${location.pathname === item.href 
                        ? 'text-emerald-800 font-medium' 
                        : 'text-emerald-600 hover:text-emerald-800'}`}
                    >
                      {item.name}
                    </Link>
                  </SheetClose>
                ))}
                <SheetClose asChild>
                  <AuthButton className="text-emerald-600 hover:text-emerald-800 text-xl w-full justify-start" />
                </SheetClose>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </nav>
  );
};

export default SharedNav;