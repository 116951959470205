import React from 'react';
import { TreePine } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";


interface LogoProps {
  href?: string;
}


export default function Logo({ href }: LogoProps) {
  const { isAuthenticated } = useAuth0();

  return (
    <Link 
      to={href ?? (isAuthenticated ? "/dashboard" : "/")} 
      className="flex items-center gap-2 hover:opacity-90 transition-opacity"
    >
      <div className="bg-gradient-to-br from-emerald-400 to-emerald-600 p-1.5 rounded-lg">
        <TreePine className="h-5 w-5 text-white" />
      </div>
      <span className="text-xl font-bold bg-gradient-to-r from-emerald-800 to-emerald-600 bg-clip-text text-transparent">
        TreeID
      </span>
    </Link>
  );
}