import React from 'react';
import { 
  TreePine, 
  Flame,
  Scale,
  Search,
  ArrowRight,
  Shield 
} from 'lucide-react';
import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import SharedNav from '../components/landing-nav';
import { useMobile } from '../contexts/app-context';


export interface Testimonial {
  name: string;
  title: string;
  quote: string;
  rating: number;
  image?: string;
  location?: string;
  useCase?: 'bbq' | 'firewood' | 'trading' | 'tree identification';
}

export const testimonials: Testimonial[] = [
  {
    name: "Eric R.",
    title: "Eagle Scout",
    quote: "I've always wanted an app like this! Using AI for wood identification is genius - it takes all the guesswork out of selecting the right wood for my campfires.",
    rating: 5,
    useCase: 'firewood',
    location: "Portland, Oregon"
  },
  {
    name: "Cooper W.",
    title: "Tech Enthusiast",
    quote: "I’ll be honest, it’s crazy Tree ID can identify the tree from that far away",
    rating: 5,
    useCase: 'tree identification',
    location: "Columbia, MO"
  }
];

const Landing = () => {
  const isMobile = useMobile();

  const useCases = [
    {
      title: "BBQ & Smoking",
      description: "Get the perfect smoke flavor by instantly identifying your smoking wood. No more guessing between hickory, apple, or cherry wood.",
      metric: "BBQ enthusiasts trust TreeID",
      icon: <Flame className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />,
      color: "from-orange-500 to-red-500",
      image: "/api/placeholder/600/400"
    },
    {
      title: "Firewood Verification",
      description: "Buy firewood with confidence. Verify wood species before purchase and ensure you're getting the best value for your heating needs.",
      metric: "Save on heating costs",
      icon: <TreePine className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />,
      color: "from-emerald-500 to-teal-500",
      image: "/api/placeholder/600/400"
    },
    {
      title: "Wood Trading",
      description: "Share instant verification links with customers. Build trust and close deals faster with accurate species identification.",
      metric: "Faster deal closure",
      icon: <Scale className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />,
      color: "from-blue-500 to-indigo-500",
      image: "/api/placeholder/600/400"
    }
  ];

  const features = [
    {
      title: "Instant Identification",
      description: "Take a photo and get accurate wood identification in seconds. Our AI model has been trained on over 100,000 wood samples.",
      icon: <Search className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />
    },
    {
      title: "Verification Links",
      description: "Generate shareable verification links for your wood identification results. Perfect for remote purchases and quality assurance.",
      icon: <Shield className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50">
      <SharedNav />

      {/* Hero Section */}
      <section className={`${isMobile ? 'pt-24 pb-12 px-4' : 'pt-48 pb-32 px-6'}`}>
        <div className="max-w-4xl mx-auto text-center space-y-12">
          <div className="flex justify-center">
            <div className={`bg-gradient-to-br from-emerald-400 to-teal-500 ${isMobile ? 'p-4' : 'p-8'} rounded-2xl shadow-lg`}>
              <TreePine className={`${isMobile ? 'h-10 w-10' : 'h-16 w-16'} text-white`} />
            </div>
          </div>
          
          <div className={`space-y-${isMobile ? '6' : '8'}`}>
            <h1 className={`${isMobile ? 'text-4xl' : 'text-7xl'} font-bold bg-gradient-to-r from-emerald-800 to-teal-700 bg-clip-text text-transparent leading-tight overflow-visible whitespace-normal pb-4`}>
              Identify Any Tree Instantly!
            </h1>
            
            <p className={`${isMobile ? 'text-lg' : 'text-2xl'} text-emerald-600 max-w-2xl mx-auto leading-relaxed`}>
              Instantly identify any type of wood with our AI-powered tool. Perfect for BBQ enthusiasts, firewood buyers, and wood traders who need accurate and quick wood species verification.
            </p>

            <div className={`flex flex-col items-center ${isMobile ? 'mt-6' : 'mt-12'}`}>
              <Button
                onClick={() => {
                  window.location.href = '/signup';
                }}
                className={`bg-gradient-to-r from-emerald-500 to-teal-500 text-white 
                  hover:from-emerald-600 hover:to-teal-600 
                  ${isMobile ? 'w-full text-lg py-6' : 'w-auto text-xl py-8'} 
                  px-12 rounded-2xl shadow-md hover:shadow-lg transition-all`}
              >
                Start Free Trial
                <ArrowRight className={`ml-2 ${isMobile ? 'h-4 w-4' : 'h-5 w-5'}`} />
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className={`${isMobile ? 'py-12' : 'py-32'} bg-white`}>
        <div className={`max-w-7xl mx-auto ${isMobile ? 'px-4' : 'px-8'}`}>
          <div className={`grid ${isMobile ? 'grid-cols-1 gap-6' : 'grid-cols-3 gap-8'}`}>
            {useCases.map((useCase, index) => (
              <Card 
                key={index}
                className="group bg-white hover:shadow-xl transition-all duration-300"
              >
                <CardHeader className={isMobile ? 'p-6' : 'p-8'}>
                  <div className={`bg-gradient-to-br ${useCase.color} ${isMobile ? 'p-3' : 'p-5'} rounded-2xl w-fit text-white shadow-md`}>
                    {useCase.icon}
                  </div>
                  <CardTitle className={`${isMobile ? 'text-xl mt-4' : 'text-2xl mt-6'} font-semibold text-emerald-800`}>
                    {useCase.title}
                  </CardTitle>
                </CardHeader>
                <CardContent className={`space-y-4 ${isMobile ? 'px-6 pb-6' : 'px-8 pb-8'}`}>
                  <p className={`${isMobile ? 'text-base' : 'text-lg'} text-emerald-600 leading-relaxed`}>
                    {useCase.description}
                  </p>
                  <div className="pt-4 border-t border-emerald-100">
                    <p className="font-semibold text-emerald-800">
                      {useCase.metric}
                    </p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

{/* Testimonials Section */}
<section className={`${isMobile ? 'py-12' : 'py-32'} bg-gradient-to-br from-emerald-50/50 to-teal-50/50`}>
        <div className={`max-w-7xl mx-auto ${isMobile ? 'px-4' : 'px-8'}`}>
          <div className={`text-center ${isMobile ? 'mb-12' : 'mb-24'}`}>
            <h2 className={`${isMobile ? 'text-2xl' : 'text-4xl'} font-bold text-emerald-800 mb-6`}>
              What Our Users Say
            </h2>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-emerald-600 max-w-2xl mx-auto`}>
              Join satisfied users who trust our AI-powered wood identification.
            </p>
          </div>

          <div className={`grid ${isMobile ? 'grid-cols-1 gap-6' : 'grid-cols-2 gap-8'}`}>
            {testimonials.map((testimonial, index) => (
              <Card 
                key={index}
                className="bg-white hover:shadow-xl transition-all duration-300 overflow-hidden"
              >
                <CardContent className={`${isMobile ? 'p-6' : 'p-8'}`}>
                  <div className="flex items-start gap-4">
                    <div className="flex-shrink-0">
                      <div className={`${isMobile ? 'w-12 h-12' : 'w-16 h-16'} rounded-full bg-gradient-to-br from-emerald-500 to-teal-500 flex items-center justify-center text-white font-semibold text-xl`}>
                        {testimonial.name.charAt(0)}
                      </div>
                    </div>
                    <div className="flex-1">
                      <p className={`${isMobile ? 'text-base' : 'text-lg'} text-emerald-600 italic mb-4`}>"{testimonial.quote}"</p>
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-semibold text-emerald-800">{testimonial.name}</p>
                          <p className="text-sm text-emerald-600">{testimonial.title}</p>
                        </div>
                        {testimonial.rating && (
                          <div className="flex items-center gap-1">
                            {[...Array(testimonial.rating)].map((_, i) => (
                              <TreePine key={i} className="w-4 h-4 text-emerald-500" />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}

      {/* Features Section */}
      <section className={`${isMobile ? 'py-12' : 'py-32'}`}>
        <div className={`max-w-7xl mx-auto ${isMobile ? 'px-4' : 'px-8'}`}>
          <div className={`text-center ${isMobile ? 'mb-12' : 'mb-24'}`}>
            <h2 className={`${isMobile ? 'text-2xl' : 'text-4xl'} font-bold text-emerald-800 mb-6`}>
              Powerful Features You'll Love
            </h2>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-emerald-600 max-w-2xl mx-auto`}>
              Everything you need for confident wood identification and verification.
            </p>
          </div>

          <div className={`grid gap-${isMobile ? '6' : '8'}`}>
            {features.map((feature) => (
              <div
                key={feature.title}
                className={`group bg-white ${isMobile ? 'p-6' : 'p-10'} rounded-3xl shadow-md hover:shadow-xl transition-all duration-300`}
              >
                <div className={`flex items-center gap-${isMobile ? '6' : '8'} mb-6`}>
                  <div className={`bg-gradient-to-br from-emerald-500 to-teal-500 ${isMobile ? 'p-3' : 'p-5'} rounded-2xl text-white shadow-md`}>
                    {feature.icon}
                  </div>
                  <h3 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-semibold text-emerald-800`}>
                    {feature.title}
                  </h3>
                </div>
                <p className={`${isMobile ? 'text-base' : 'text-lg'} text-emerald-600 leading-relaxed`}>
                  {feature.description}
                </p>
                <div className="h-1 w-16 bg-gradient-to-r from-emerald-500 to-teal-500 rounded-full mt-6" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;