import React, { useState, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Maximize2, X } from 'lucide-react';
import { Button } from './ui/button';
import { Dialog, DialogContent } from './ui/dialog';
import { cn } from '../lib/utils';
import { useMobile } from '../contexts/app-context';
import type { File } from '../client/types.gen';

interface TreeGalleryProps {
  images: File[];
  imageUrls: Record<string, string>;
}

const TreeGallery: React.FC<TreeGalleryProps> = ({ images, imageUrls }) => {
  const isMobile = useMobile();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [touchStart, setTouchStart] = useState(0);

  if (!images?.length) return null;

  const handleNext = useCallback(() => {
    setCurrentIndex(prev => (prev + 1) % images.length);
  }, [images.length]);

  const handlePrevious = useCallback(() => {
    setCurrentIndex(prev => 
      prev === 0 ? images.length - 1 : prev - 1
    );
  }, [images.length]);

  const getCurrentImageUrl = useCallback((index: number) => {
    const image = images[index];
    if (!image?.id) return '';
    return imageUrls[image.id] || '';
  }, [images, imageUrls]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const touchEnd = e.changedTouches[0].clientX;
    const diff = touchStart - touchEnd;
    if (Math.abs(diff) > 50) {
      if (diff > 0) handleNext();
      else handlePrevious();
    }
  };

  const currentImageUrl = getCurrentImageUrl(currentIndex);
  if (!currentImageUrl) return null;

  return (
    <div className="w-full space-y-4">
      <div 
        className="relative rounded-xl overflow-hidden bg-gray-100 border-2 border-emerald-100 shadow-lg"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <img
          src={currentImageUrl}
          alt={`Tree view ${currentIndex + 1}`}
          className={cn(
            "w-full object-cover",
            isMobile ? "aspect-square" : "aspect-video"
          )}
        />
        
        {images.length > 1 && (
          <>
            {!isMobile && (
              <>
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white"
                  onClick={handlePrevious}
                >
                  <ChevronLeft className="h-8 w-8" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white"
                  onClick={handleNext}
                >
                  <ChevronRight className="h-8 w-8" />
                </Button>
              </>
            )}

            {isMobile && (
              <div className="absolute bottom-4 left-1/2 -translate-x-1/2">
                <div className="flex gap-2">
                  {images.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentIndex(index)}
                      className={cn(
                        "h-2 rounded-full transition-all",
                        currentIndex === index
                          ? "w-6 bg-white"
                          : "w-2 bg-white/60"
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}

        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2 bg-white/80 hover:bg-white"
          onClick={() => setIsFullscreen(true)}
        >
          <Maximize2 className="h-5 w-5" />
        </Button>
      </div>

      {!isMobile && images.length > 1 && (
        <div className="flex gap-2 overflow-x-auto pb-2">
          {images.map((_, index) => {
            const thumbUrl = getCurrentImageUrl(index);
            if (!thumbUrl) return null;
            
            return (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={cn(
                  "flex-shrink-0 rounded-lg overflow-hidden border-2 transition-colors",
                  currentIndex === index 
                    ? "border-emerald-500" 
                    : "border-transparent hover:border-emerald-200"
                )}
              >
                <img
                  src={thumbUrl}
                  alt={`Tree view ${index + 1}`}
                  className="h-20 w-20 object-cover"
                />
              </button>
            );
          })}
        </div>
      )}

      <Dialog open={isFullscreen} onOpenChange={setIsFullscreen}>
        <DialogContent className="max-w-7xl p-0">
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 top-2 z-50 bg-white/80 hover:bg-white"
            onClick={() => setIsFullscreen(false)}
          >
            <X className="h-5 w-5" />
          </Button>
          <img
            src={currentImageUrl}
            alt={`Tree view ${currentIndex + 1}`}
            className="w-full h-full object-contain"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TreeGallery;