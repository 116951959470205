// hooks/use-logout.ts
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useApp } from '../contexts/app-context';

export const useLogout = () => {
  const { resetState } = useApp();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    // 1. Set logging out flag
    localStorage.setItem('isLoggingOut', 'true');
    
    // 2. Clear app state
    resetState();

    // 3. Navigate to home 
    navigate('/', { replace: true });

    // 4. Force clear Auth0 cookies and local storage
    localStorage.clear();
    localStorage.setItem('isLoggingOut', 'true'); // Keep this flag
    
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // 5. Force reload the page to clear all auth state
    window.location.href = '/';

    // The isLoggingOut flag will be cleared on next page load
    setTimeout(() => {
      localStorage.removeItem('isLoggingOut');
    }, 1000);
  }, [resetState, navigate]);

  return handleLogout;
};