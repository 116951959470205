import React, { useEffect } from 'react';
import { 
  Shield,
  FileText,
  UserCircle,
  Trash2,
  AlertCircle,
  Scale
} from 'lucide-react';
import SharedNav from '../components/landing-nav';
import { useMobile } from '../contexts/app-context';

const sections = [
  {
    title: "Account Terms",
    icon: UserCircle,
    content: "By creating an account on TreeID, you agree to provide accurate and complete information. You are responsible for maintaining the security of your account and password. TreeID cannot and will not be liable for any loss or damage from your failure to comply with this security obligation."
  },
  {
    title: "Privacy & Data",
    icon: Shield,
    content: "We take the privacy of your data seriously. Your uploaded images and analysis results are stored securely and are only accessible to you unless you choose to make them public. We do not sell your personal data to third parties. Upon account deletion, all your data will be permanently removed from our systems."
  },
  {
    title: "Acceptable Use",
    icon: AlertCircle,
    content: "You agree to use TreeID for legitimate wood and tree identification purposes. Any attempt to reverse engineer, damage, or gain unauthorized access to our systems is prohibited. We reserve the right to suspend or terminate accounts that violate these terms."
  },
  {
    title: "Subscription & Billing",
    icon: Scale,
    content: "Subscription fees are billed in advance on a monthly basis. You can cancel your subscription at any time, and you will retain access to TreeID until the end of your current billing period. No refunds are provided for partial month subscriptions."
  },
  {
    title: "Content Ownership",
    icon: FileText,
    content: "You retain all rights to the images you upload to TreeID. By uploading content, you grant us a license to store, process, and display your content for the purpose of providing our services. This license ends when you delete your content or account."
  },
  {
    title: "Account Deletion",
    icon: Trash2,
    content: "You can delete your account at any time. Upon deletion, all your personal information and uploaded content will be permanently removed from our systems within 30 days. We may retain certain data as required by law or for legitimate business purposes."
  }
];

export default function TermsPage() {
  const isMobile = useMobile();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50">
      <SharedNav />

      <main className={`${isMobile ? 'pt-24' : 'pt-32'}`}>
        {/* Hero Section */}
        <section className={`${isMobile ? 'pb-12 px-4' : 'pb-20 px-6'}`}>
          <div className="max-w-4xl mx-auto text-center space-y-8">
            <div className="flex justify-center">
              <div className={`bg-gradient-to-br from-emerald-400 to-teal-500 ${isMobile ? 'p-4' : 'p-6'} rounded-2xl shadow-lg`}>
                <FileText className={`${isMobile ? 'h-8 w-8' : 'h-12 w-12'} text-white`} />
              </div>
            </div>
            
            <h1 className={`${isMobile ? 'text-4xl' : 'text-6xl'} font-bold bg-gradient-to-r from-emerald-800 to-teal-700 bg-clip-text text-transparent leading-tight`}>
              Terms of Service
            </h1>
            
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-emerald-600 max-w-2xl mx-auto`}>
              These terms govern your use of TreeID. By using our service, you agree to these terms.
            </p>
          </div>
        </section>

        {/* Terms Sections */}
        <section className={`${isMobile ? 'py-12' : 'py-20'} bg-white`}>
          <div className={`max-w-4xl mx-auto ${isMobile ? 'px-4' : 'px-6'}`}>
            <div className={`space-y-${isMobile ? '8' : '12'}`}>
              {sections.map((section, index) => (
                <div key={index} className="group">
                  <div className="flex items-start gap-6">
                    <div className={`flex-shrink-0 ${isMobile ? 'mt-1' : 'mt-2'}`}>
                      <div className={`bg-gradient-to-br from-emerald-500 to-teal-500 ${isMobile ? 'p-3' : 'p-4'} rounded-xl text-white shadow-md`}>
                        <section.icon className={`${isMobile ? 'h-5 w-5' : 'h-6 w-6'}`} />
                      </div>
                    </div>
                    <div>
                      <h2 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-semibold text-emerald-800 mb-3`}>
                        {section.title}
                      </h2>
                      <p className={`${isMobile ? 'text-base' : 'text-lg'} text-emerald-600 leading-relaxed`}>
                        {section.content}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Last Updated */}
        <section className={`${isMobile ? 'py-12' : 'py-20'}`}>
          <div className="max-w-4xl mx-auto px-4 text-center">
            <p className="text-emerald-600">
              Last updated: January 2025
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}