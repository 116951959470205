import React, { useEffect } from 'react';
import { 
  ShieldCheck, 
  Lock, 
  Image, 
  CreditCard,
  Key,
  Server,
  MessageSquareWarning,
  CheckCircle2
} from 'lucide-react';
import SharedNav from '../components/landing-nav';
import { useMobile } from '../contexts/app-context';

export default function PrivacyPage() {
  const isMobile = useMobile();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const privacyPoints = [
    {
      title: 'Zero Trust Architecture',
      description: 'Built with security-first principles. Every request is authenticated and authorized, regardless of source.',
      icon: <Lock className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />,
    },
    {
      title: 'Complete Data Control',
      description: 'Your uploads belong to you. Request complete data export or deletion at any time.',
      icon: <ShieldCheck className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />,
    },
    {
      title: 'Private by Default',
      description: 'All uploads are private. Sharing is opt-in only.',
      icon: <Image className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />,
    },
    {
      title: 'Secure Infrastructure',
      description: 'Enterprise-grade cloud infrastructure with regular security audits and threat detection.',
      icon: <Server className={`${isMobile ? 'h-6 w-6' : 'h-8 w-8'}`} />,
    }
  ];

  const securityMeasures = [
    {
      title: 'End-to-End Encryption',
      description: 'Industry-standard AES-256 encryption in transit and at rest',
      icon: <Key className="h-5 w-5 text-emerald-600" />,
    },
    {
      title: 'Secure Payments',
      description: 'Stripe-powered payment processing with zero data storage',
      icon: <CreditCard className="h-5 w-5 text-emerald-600" />,
    },
    {
      title: 'Bug Bounty Program',
      description: 'Active security research and vulnerability management',
      icon: <MessageSquareWarning className="h-5 w-5 text-emerald-600" />,
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50/30">
      <SharedNav />

      <main className={`pt-${isMobile ? '16' : '20'}`}>
        {/* Hero Section */}
        <section className={`relative overflow-hidden pt-${isMobile ? '24' : '32'} pb-${isMobile ? '16' : '24'}`}>
          <div className="absolute inset-0 bg-gradient-to-b from-emerald-50 to-white pointer-events-none" />
          <div className={`relative max-w-screen-xl mx-auto ${isMobile ? 'px-4' : 'px-6'}`}>
            <div className="text-center space-y-8">
              <div className="inline-flex p-3 rounded-2xl bg-emerald-100/50 backdrop-blur-sm">
                <div className={`bg-gradient-to-br from-emerald-500 to-emerald-600 ${isMobile ? 'p-4' : 'p-5'} rounded-xl shadow-lg`}>
                  <Lock className={`${isMobile ? 'h-8 w-8' : 'h-10 w-10'} text-white`} />
                </div>
              </div>
              
              <div className="max-w-3xl mx-auto space-y-6">
                <h1 className={`${isMobile ? 'text-4xl' : 'text-6xl'} font-bold bg-gradient-to-r from-emerald-800 to-emerald-600 bg-clip-text text-transparent`}>
                  Security First, Always
                </h1>
                <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-emerald-600 max-w-2xl mx-auto leading-relaxed`}>
                  Built by security professionals with a zero-trust approach. Your privacy isn't just a feature - it's our foundation.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Privacy Points Grid */}
        <section className={`py-${isMobile ? '16' : '24'} bg-white`}>
          <div className={`max-w-screen-xl mx-auto ${isMobile ? 'px-4' : 'px-6'}`}>
            <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-${isMobile ? '6' : '8'}`}>
              {privacyPoints.map((point) => (
                <div
                  key={point.title}
                  className={`group relative bg-gradient-to-br from-emerald-50 to-transparent ${isMobile ? 'p-6' : 'p-8'} rounded-2xl hover:shadow-lg transition-all duration-300`}
                >
                  <div className="flex gap-6">
                    <div className={`bg-gradient-to-br from-emerald-500 to-emerald-600 ${isMobile ? 'p-3' : 'p-4'} rounded-xl text-white shadow-md`}>
                      {point.icon}
                    </div>
                    <div className="space-y-3">
                      <h3 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-semibold text-emerald-800`}>
                        {point.title}
                      </h3>
                      <p className="text-emerald-600 leading-relaxed">
                        {point.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Security Features */}
        <section className={`py-${isMobile ? '16' : '24'} bg-gradient-to-br from-emerald-50 to-emerald-100/30`}>
          <div className={`max-w-screen-xl mx-auto ${isMobile ? 'px-4' : 'px-6'}`}>
            <div className="max-w-3xl mx-auto">
              <div className="text-center space-y-4 mb-12">
                <h2 className={`${isMobile ? 'text-3xl' : 'text-4xl'} font-bold text-emerald-800`}>
                  Enterprise-Grade Security
                </h2>
                <p className="text-lg text-emerald-600">
                  Industry-leading security measures to protect your data
                </p>
              </div>

              <div className="space-y-6">
                {securityMeasures.map((measure) => (
                  <div
                    key={measure.title}
                    className="flex items-start gap-4 bg-white/80 backdrop-blur-sm p-4 rounded-xl"
                  >
                    <div className="bg-emerald-100 p-2 rounded-lg">
                      <CheckCircle2 className="h-5 w-5 text-emerald-600" />
                    </div>
                    <div>
                      <div className="flex items-center gap-2 mb-1">
                        <h3 className="text-lg font-medium text-emerald-800">
                          {measure.title}
                        </h3>
                        {measure.icon}
                      </div>
                      <p className="text-emerald-600">
                        {measure.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Data Rights & Commitment */}
        <section className={`py-${isMobile ? '16' : '24'} bg-white`}>
          <div className={`max-w-screen-xl mx-auto ${isMobile ? 'px-4' : 'px-6'}`}>
            <div className="max-w-3xl mx-auto">
              <div className={`grid gap-${isMobile ? '12' : '16'}`}>
                <div className="space-y-4">
                  <h2 className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-bold text-emerald-800`}>
                    Your Data Rights
                  </h2>
                  <p className="text-lg text-emerald-600 leading-relaxed">
                    We believe in complete data transparency and control. You can request a full export of your data or complete deletion at any time. When you delete your data, we ensure it's completely removed from our systems, including backups.
                  </p>
                </div>

                <div className="space-y-4">
                  <h2 className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-bold text-emerald-800`}>
                    Our Commitment
                  </h2>
                  <p className="text-lg text-emerald-600 leading-relaxed">
                    As a security-focused development team, we're committed to maintaining the highest standards of data protection. We regularly update our security measures and conduct third-party audits to ensure your data remains safe.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}