import React, { useState, useRef, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogCancel,
} from './ui/alert-dialog';
import { Progress } from './ui/progress';
import { Trash2, AlertCircle } from 'lucide-react';
import { getTreesByTreeId } from '../client/sdk.gen';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: (treeId?: string) => void;
  treeId?: string;
  treeName?: string;
}

const DELETE_HOLD_DURATION = 1000; // 1 second hold to delete

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  treeId,
  treeName = 'this tree'
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [holdProgress, setHoldProgress] = useState(0);
  const holdTimerRef = useRef<number>(0);
  const startTimeRef = useRef<number>(0);

  useEffect(() => {
    return () => {
      if (holdTimerRef.current) {
        cancelAnimationFrame(holdTimerRef.current);
      }
    };
  }, []);

  const handleDeleteStart = () => {
    startTimeRef.current = Date.now();
    const animate = () => {
      const elapsed = Date.now() - startTimeRef.current;
      const progress = Math.min((elapsed / DELETE_HOLD_DURATION) * 100, 100);
      setHoldProgress(progress);

      if (progress < 100) {
        holdTimerRef.current = requestAnimationFrame(animate);
      } else {
        handleDeleteComplete();
      }
    };
    holdTimerRef.current = requestAnimationFrame(animate);
  };

  const handleDeleteEnd = () => {
    cancelAnimationFrame(holdTimerRef.current);
    setHoldProgress(0);
  };

  const handleDeleteComplete = async () => {
    if (!treeId) return;
    try {
      setIsDeleting(true);
      await getTreesByTreeId({ path: { treeId } });
      onDelete(treeId);
    } catch (error) {
      console.error('Error deleting tree:', error);
    } finally {
      setIsDeleting(false);
      setHoldProgress(0);
      onClose();
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent
        className="max-w-md border border-emerald-200 shadow-2xl bg-gradient-to-r from-emerald-50 to-white text-emerald-900"
      >
        <AlertDialogHeader className="gap-6">
          <AlertDialogTitle className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-xl text-emerald-800">
              <Trash2 className="h-6 w-6 text-red-500" />
              <span>Delete {treeName}</span>
            </div>
            <AlertDialogCancel className="rounded-full h-8 w-8 p-0 border-0 text-emerald-800 hover:bg-emerald-100">
              ✕
            </AlertDialogCancel>
          </AlertDialogTitle>

          <div className="space-y-6">
            <div className="flex items-start gap-3 p-4 bg-red-50 rounded-xl text-red-800">
              <AlertCircle className="h-5 w-5 mt-0.5 flex-shrink-0" />
              <div className="space-y-1 text-sm">
                <p className="font-medium">This cannot be undone</p>
                <p className="text-red-700/90">
                  All data and images associated with this tree will be permanently deleted.
                </p>
              </div>
            </div>

            <div className="space-y-3">
              <button
                onMouseDown={handleDeleteStart}
                onMouseUp={handleDeleteEnd}
                onMouseLeave={handleDeleteEnd}
                onTouchStart={handleDeleteStart}
                onTouchEnd={handleDeleteEnd}
                disabled={isDeleting}
                className="w-full py-3 px-4 rounded-lg bg-red-500 hover:bg-red-600 
                  active:bg-red-700 text-white font-medium transition-all 
                  focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2
                  disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isDeleting ? (
                  <span>Deleting...</span>
                ) : (
                  <span>Hold to delete</span>
                )}
              </button>

              <Progress
                value={holdProgress}
                className="h-1 bg-red-100"
                indicatorClassName="bg-red-500"
              />

              <p className="text-center text-sm text-gray-700">
                Hold the button to confirm deletion
              </p>
            </div>
          </div>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteModal;