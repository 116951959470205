import { createClient, createConfig } from '@hey-api/client-axios';
// Import the specific functions we need for public routes
import type { 
  GetTreesPublicByTreeIdResponse,
  GetFilesPublicByFileIdResponse,
} from '../client/types.gen';
import config from '../config';

// Create a separate client instance for public routes
// Create the public client instance
const publicClient = createClient(
  createConfig({
    baseURL: config.apiBaseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  })
);

// Create wrapper functions for public endpoints
export const getPublicTree = async (treeId: string): Promise<GetTreesPublicByTreeIdResponse> => {
  const response = await publicClient.get({
    url: `/trees/public/${treeId}`,
  });
  return response;
};

export const getPublicFile = async (fileId: string): Promise<GetFilesPublicByFileIdResponse> => {
  const response = await publicClient.get({
    url: `/files/public/${fileId}`,
  });
  return response;
};

export { publicClient };