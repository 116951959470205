import React from 'react';
import { 
  TreePine, 
  ArrowRight,
  BadgeCheck,
  Camera,
  ThumbsUp,
  Users,
} from 'lucide-react';
import { Button } from '../components/ui/button';
import SharedNav from '../components/landing-nav';
import { useMobile } from '../contexts/app-context';
import AngledImage from '../components/photo-angeled';


const LandingAdPage = () => {

    const redditPosts = [
        {
            subreddit: "r/firewood",
            link: "https://www.reddit.com/r/firewood/comments/1ig48et/comment/mazrgjm/",
            answerDate: "2025",
            mytreeidlink: "https://www.mytreeid.com/trees/public/4dd2aed1-ee84-4fe9-8ed7-9d8aff1401d8"
        },
        {
            subreddit: "r/firewood",
            link: "https://www.reddit.com/r/firewood/comments/1igr1qk/comment/mazqz80/",
            answerDate: "2025",
            mytreeidlink: "https://www.mytreeid.com/trees/public/e93d5e0d-f0fa-493f-a366-5215a7e8c78a"
        },
        {
            subreddit: "r/Tree",
            link: "https://www.reddit.com/r/Tree/comments/1ihhixz/comment/maym739/",
            answerDate: "2025",
            mytreeidlink: "https://www.mytreeid.com/trees/public/1f43003d-fbdc-46da-9529-251ff04e466c"
        },
        {
            subreddit: "r/NativePlantGardening",
            link: "https://www.reddit.com/r/NativePlantGardening/comments/1igol1f/comment/maqobb5/",
            answerDate: "2025",
            mytreeidlink: "https://www.mytreeid.com/trees/public/a112f682-971b-4033-9fe4-831248d28d86"
        }
        ];
  const isMobile = useMobile();

  const features = [
    {
      icon: <Camera />,
      title: "Instant Identification",
      description: "Take a photo of any tree or wood and get an instant, accurate identification powered by AI."
    },
    {
      icon: <BadgeCheck />,
      title: "Expert Verified",
      description: "Our AI model is trained on 100,000+ expert-verified wood samples for high accuracy."
    },
    {
      icon: <ThumbsUp />,
      title: "Free to Try",
      description: "7-day free trial, then just $7/month."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 to-teal-50">
      <SharedNav />

      <main className={`${isMobile ? 'pt-20' : 'pt-28'}`}>
        {/* Hero Section */}
        <section className={`${isMobile ? 'py-12 px-4' : 'py-20 px-6'}`}>
          <div className="max-w-4xl mx-auto text-center space-y-8">
            <div className="space-y-4">
              <div className="bg-gradient-to-r from-emerald-500/10 to-teal-500/10 border border-emerald-200 rounded-xl p-4">
                <p className="text-emerald-800 font-semibold">
                  🎉 Use code <span className="font-mono bg-white px-2 py-1 rounded text-emerald-600">ZT4eH1wk</span> for 50% off today!
                </p>
              </div>
            </div>
            <div className="relative">
            <div className="absolute left-0 transform -translate-x-full top-32 hidden lg:block w-64">
              </div>

              <div className="absolute right-0 transform translate-x-full top-32 hidden lg:block w-64">
                <AngledImage
                  src="/MyTreeID graphic.png"
                  alt="MyTreeID graphic"
                  angle="-25"
                />
              </div>

              <h1 className={`${isMobile ? 'text-4xl' : 'text-6xl'} font-bold bg-gradient-to-r from-emerald-800 to-teal-700 bg-clip-text text-transparent leading-tight`}>
                Professional Tree Identification in Seconds
              </h1>
            </div>

            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-emerald-600 max-w-2xl mx-auto`}>
              Stop guessing what tree species you're looking at. TreeID uses advanced AI to instantly identify any tree from a photo with expert-level accuracy.
            </p>

            <Button
              onClick={() => {
                window.location.href = '/signup';
              }}
              className={`bg-gradient-to-r from-emerald-500 to-teal-500 text-white 
                hover:from-emerald-600 hover:to-teal-600 
                ${isMobile ? 'w-full text-lg py-6' : 'w-auto text-xl py-8'} 
                px-12 rounded-2xl shadow-md hover:shadow-lg transition-all`}
            >
              Start Free Trial
              <ArrowRight className={`ml-2 ${isMobile ? 'h-4 w-4' : 'h-5 w-5'}`} />
            </Button>

            <p className="text-emerald-600 text-sm">
              Cancel anytime
            </p>
          </div>
        </section>

        <section className={`${isMobile ? 'py-12' : 'py-20'} bg-white`}>
          <div className={`max-w-4xl mx-auto ${isMobile ? 'px-4' : 'px-6'}`}>
            <h2 className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-bold text-emerald-800 text-center mb-8`}>
              Sound Familiar? We Built This For You.
            </h2>
            
            <div className="space-y-4">
              {redditPosts.map((post, index) => (
                <div 
                  key={index}
                  className="bg-white border border-emerald-100 rounded-xl p-6 shadow-sm hover:shadow-md transition-all"
                >
                  <div className="flex items-start gap-4">
                    <TreePine className="h-6 w-6 text-emerald-500 flex-shrink-0 mt-1" />
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <p className="text-emerald-600 text-sm font-medium">
                          {post.subreddit}
                        </p>
                        <span className="text-emerald-500 text-sm">
                          Tree Identified {post.answerDate}
                        </span>
                      </div>
                      <div className="mt-4 flex flex-col sm:flex-row gap-3">
                        <a 
                          href={post.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center justify-center px-4 py-2 bg-emerald-100 text-emerald-700 rounded-lg hover:bg-emerald-200 transition-colors"
                        >
                          View on Reddit →
                        </a>
                        <a 
                          href={post.mytreeidlink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-lg hover:from-emerald-600 hover:to-teal-600 transition-colors"
                        >
                          See TreeID Analysis →
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className={`${isMobile ? 'py-12' : 'py-20'}`}>
          <div className={`max-w-4xl mx-auto ${isMobile ? 'px-4' : 'px-6'}`}>
            <div className={`grid ${isMobile ? 'grid-cols-1 gap-6' : 'grid-cols-3 gap-8'}`}>
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-xl border border-emerald-100 shadow-sm hover:shadow-md transition-all"
                >
                  <div className="bg-emerald-100 w-12 h-12 rounded-lg flex items-center justify-center text-emerald-600 mb-4">
                    {feature.icon}
                  </div>
                  <h3 className={`${isMobile ? 'text-xl' : 'text-lg'} font-semibold text-emerald-800 mb-2`}>
                    {feature.title}
                  </h3>
                  <p className="text-emerald-600">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className={`${isMobile ? 'py-12' : 'py-20'} bg-gradient-to-br from-emerald-500 to-teal-600`}>
          <div className={`max-w-4xl mx-auto ${isMobile ? 'px-4' : 'px-6'} text-center`}>
            <h2 className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-bold text-white mb-6`}>
              Ready to Start Identifying Trees Like a Pro?
            </h2>
            <Button
              onClick={() => {
                window.location.href = '/signup';
              }}
              className={`bg-white text-emerald-600 hover:bg-emerald-50
                ${isMobile ? 'w-full text-lg py-6' : 'w-auto text-xl py-8'} 
                px-12 rounded-2xl shadow-md hover:shadow-lg transition-all`}
            >
              Start Your Free Trial
              <ArrowRight className={`ml-2 ${isMobile ? 'h-4 w-4' : 'h-5 w-5'}`} />
            </Button>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingAdPage;