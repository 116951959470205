interface Config {
    apiBaseUrl: string;
    auth0: {
      domain: string;
      clientId: string;
      audience: string;
    };
    Environment: string;
  }
  
  const baseConfig: Config = {
    apiBaseUrl: 'https://z6kiqnzvm72kdoparvcmq5e5sy0rbrhm.lambda-url.us-east-1.on.aws',
    auth0: {
      domain: 'login.mytreeid.com',

      // Dev YYkiRbtdkYYP4P3CtEuMvD50L4SDi0SS
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'JQ0Wvh1eSqOBRmVBUikXiCZhb8HE5LdZ',
      audience: 'tree-api'
    },
    Environment: process.env.ENVIRONMENT || 'production'
  };
  
  const config: Config = {
    ...baseConfig,
    apiBaseUrl: process.env.REACT_APP_API_URL || baseConfig.apiBaseUrl,
  };

export default config;