import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from './ui/card';
import { Stars, Mail, Chrome, TreePine } from 'lucide-react';
import { useApp } from '../contexts/app-context';

const SocialLoginButton = ({ icon: Icon, onClick, label }) => (
  <Button 
    variant="outline" 
    className="w-full bg-white hover:bg-emerald-50 border-emerald-200 text-emerald-700 p-6 text-lg"
    onClick={onClick}
  >
    <Icon className="mr-3 h-6 w-6" />
    {label}
  </Button>
);

interface AuthDialogProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const AuthDialog = ({ open, onOpenChange }: AuthDialogProps) => {
  const { loginWithPopup, user, isAuthenticated } = useAuth0();
  const { subscriptionPass, fetchSubscription } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && subscriptionPass) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, subscriptionPass]);

  const handleEmailLogin = async () => {
    try {
      await loginWithPopup({
        authorizationParams: {
          connection: 'Username-Password-Authentication',
          prompt: 'login',
        },
      });
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleSocialLogin = async (connection: string) => {
    try {
      await loginWithPopup({
        authorizationParams: { 
          connection: connection === 'email' ? 'Username-Password-Authentication' : connection
        },
      });

    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const renderDialogContent = () => (
    <Card className="border-0 shadow-none">
      <CardHeader className="space-y-4 p-6">
        <div className="mx-auto bg-gradient-to-br from-emerald-400 to-teal-500 w-16 h-16 rounded-xl flex items-center justify-center">
          <Stars className="h-8 w-8 text-white" />
        </div>
        <CardTitle className="text-3xl text-center text-emerald-800 font-bold">
          Welcome to TreeID Pro
        </CardTitle>
        <CardDescription className="text-center text-emerald-600 text-lg">
          Choose how you'd like to continue
        </CardDescription>
      </CardHeader>
      <CardContent className="px-6 pb-8">
        {user ? (
          <div className="w-full p-6 bg-emerald-50 rounded-xl border border-emerald-200">
            <div className="flex items-center space-x-4">
              <div className="h-14 w-14 bg-emerald-600 rounded-full flex items-center justify-center">
                <Mail className="h-7 w-7 text-white" />
              </div>
              <div>
                <p className="font-medium text-emerald-800 text-lg">Signed in as</p>
                <p className="text-emerald-600 text-lg">{user.email}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <SocialLoginButton
              provider="google"
              icon={Chrome}
              label="Continue with Google"
              onClick={() => handleSocialLogin('google-oauth2')}
            />
            <SocialLoginButton
              provider="email"
              icon={Mail}
              label="Continue with Email"
              onClick={handleEmailLogin}
            />
          </div>
        )}
      </CardContent>
      <CardFooter className="text-center text-gray-500 px-6 pb-6">
        <p className="w-full text-base">
          By continuing, you agree to our{' '}
          <a href="/terms" className="text-emerald-600 hover:text-emerald-700 underline">
            Terms
          </a>
          {' '}and{' '}
          <a href="/privacy" className="text-emerald-600 hover:text-emerald-700 underline">
            Privacy
          </a>
        </p>
      </CardFooter>
    </Card>
  );

  if (open !== undefined) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-xl w-[95vw] border-0 shadow-2xl bg-white/95 backdrop-blur-lg">
          {renderDialogContent()}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button 
          className="w-full bg-gradient-to-r from-emerald-500 to-teal-500 
                   hover:from-emerald-600 hover:to-teal-600 text-white p-6 text-lg"
          size="lg"
        >
          <TreePine className="mr-3 h-6 w-6" />
          Login
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl w-[95vw] border-0 shadow-2xl bg-white/95 backdrop-blur-lg">
        {renderDialogContent()}
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialog;