import React from 'react';
import './App.css';
import TreeLanding from './pages/treelanding';
import Landing from './pages/landing';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import AccountPage from './components/account';
import config from './config';
import { AppProvider } from './contexts/app-context';
import TreeDetail from './pages/tree-detail';
import PublicTreeView from './components/public-tree-view';
import { Toaster } from './components/ui/toaster';
import AboutPage from './pages/about';
import PrivacyPage from './pages/privacy';
import PricingPage from './pages/pricing';
import Footer from './components/footer';
import SignupPage from './pages/signup-page';
import Support from './pages/support';
import TermsPage from './pages/terms';
import RedditLandingPage from './pages/reddit';
import LandingAdPage from './pages/landing-ad';


// Public routes that don't need auth
export const PUBLIC_ROUTES: readonly string[] = ['/trees/public'];

const ProtectedRoute = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: location.pathname }
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, location]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-emerald-500 border-t-transparent"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return <Outlet />;
};

// Component to wrap routes that need authentication
const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const isPublicRoute = PUBLIC_ROUTES.some(route => location.pathname.startsWith(route));
  const isLogout = location.pathname === '/' && localStorage.getItem('isLoggingOut') === 'true';

  if (isPublicRoute && location.pathname.startsWith('/trees/public')) {
    return <>{children}</>;
  }

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/dashboard`,
        audience: config.auth0.audience
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

function App() {
  return (
    <Router>
      <AuthWrapper>
        <AppProvider>
          <div className="min-h-screen flex flex-col">
            <div className="flex-1">
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Landing />} />
                <Route path="/trees/public/:treeId" element={<PublicTreeView />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/reddit" element={<RedditLandingPage />} />
                <Route path="/welcome" element={<LandingAdPage />} />
                
                {/* Protected Routes */}
                <Route element={<ProtectedRoute />}>
                  <Route path="/trees/:treeId" element={<TreeDetail />} />
                  <Route path="/dashboard" element={<TreeLanding />} />
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="/support" element={<Support />} />
                </Route>
              </Routes>
            </div>
            <Footer />
          </div>
          <Toaster />
        </AppProvider>
      </AuthWrapper>
    </Router>
  );
}

export default App;