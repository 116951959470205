import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { ChevronLeft, Mail } from 'lucide-react';
import Navigation from '../components/navigation';
import { useApp } from '../contexts/app-context';
import { useMobile } from '../contexts/app-context';

const Support = () => {
  const navigate = useNavigate();
  const { getSupportEmail } = useApp();
  const [email, setEmail] = useState('');
  const isMobile = useMobile();

  useEffect(() => {
    getSupportEmail().then(setEmail);
  }, [getSupportEmail]);
  
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-emerald-50 to-teal-50">
      <Navigation onUploadClick={() => {}} />
      <div className={`flex-1 w-full max-w-7xl mx-auto ${isMobile ? 'px-3' : 'px-4'} py-6`}>
        <Button
          variant="ghost"
          onClick={() => navigate('/dashboard')}
          className={`${isMobile ? 'mb-4 py-6 px-4 text-lg' : 'mb-6'} text-emerald-700 hover:text-emerald-800`}
        >
          <ChevronLeft className={`${isMobile ? 'h-8 w-8' : 'h-4 w-4'} mr-2`} />
          Back to Dashboard
        </Button>

        <div className={`max-w-2xl mx-auto ${isMobile ? 'mt-8' : 'mt-12'} text-center`}>
          <div className={`bg-white ${isMobile ? 'p-8' : 'p-8'} rounded-3xl shadow-xl border-2 border-emerald-100`}>
            <div className={`bg-emerald-100 ${isMobile ? 'w-24 h-24' : 'w-16 h-16'} rounded-full flex items-center justify-center mx-auto ${isMobile ? 'mb-6' : 'mb-6'}`}>
              <Mail className={`${isMobile ? 'h-12 w-12' : 'h-8 w-8'} text-emerald-600`} />
            </div>
            <h1 className={`${isMobile ? 'text-3xl' : 'text-2xl'} font-semibold text-gray-800 mb-4`}>
              Need Help?
            </h1>
            <p className={`text-gray-600 ${isMobile ? 'mb-6 text-xl' : 'mb-6 text-base'}`}>
              For help or questions, reach out to us at:
            </p>
            <a
              href={`mailto:${email}`}
              className={`inline-block ${isMobile ? 'text-2xl' : 'text-lg'} font-medium text-emerald-600 hover:text-emerald-700 transition-colors break-all p-2`}
            >
              {email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;