import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TreePine, Share2, Trash2, Loader2, Camera, Plus } from 'lucide-react';
import { Card, CardContent, CardFooter } from './ui/card';
import { Button } from './ui/button';
import DeleteModal from './delete-modal';
import { useApp } from '../contexts/app-context';
import { useToast } from '../hooks/use-toast';
import { postTreesByTreeIdAnalysis } from '../client/sdk.gen';
import SubscriptionRequired from './sub-required';
import { copyToClipboard } from './toast-provider';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const TreeList = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { isAuthenticated } = useAuth0();
  const { 
    trees, 
    isLoading,
    deleteTree,
    treeDetailsCache,
    imageUrls,
    updateTree,
    subscriptionPass,
    isInitialized,
    setIsUploadOpen,
    loadTreeDetails,
    loadTrees,
    isLoggingOut,
  } = useApp();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [treeToDelete, setTreeToDelete] = useState(null);
  const [analyzingTreeId, setAnalyzingTreeId] = useState(null);


  useEffect(() => {
    if (isAuthenticated && !isLoggingOut) {
      loadTrees();
    }
  }, [isAuthenticated, isLoggingOut, loadTrees]);

  const handleAnalyzeClick = async (treeId) => {
    setAnalyzingTreeId(treeId);
    try {
      const resp = await postTreesByTreeIdAnalysis({
        path: { treeId },
        body: {},
      });

      if (resp.status === 429) {
        const minutesRemaining = Math.ceil(resp.response.data.remainingSeconds / 60);
        toast({
          title: 'Rate limit exceeded',
          description: minutesRemaining ? `Please wait about ${minutesRemaining} minute(s) to try again.` : 'Please wait a bit before trying again.',
          variant: 'destructive'
        });
        return;
      }

      await loadTreeDetails(treeId, true);
      navigate(`/trees/${treeId}`);

      toast({
        title: "Success",
        description: "Tree analysis completed successfully",
        className: "bg-white border-2 border-emerald-600",
      });
    } catch (error) {
      console.error('Error analyzing tree:', error);
      toast({
        title: "Error",
        description: "Failed to analyze tree",
        variant: "destructive"
      });
    } finally {
      setAnalyzingTreeId(null);
    }
  };

  if (isInitialized && !subscriptionPass && !isLoading) {
    return <SubscriptionRequired />;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-12 w-12 animate-spin text-emerald-500" />
      </div>
    );
  }

  const UploadCard = () => (
    <Link
      to="#"
      onClick={(e) => {
        e.preventDefault();
        setIsUploadOpen(true);
      }}
      className="group block w-full h-full transition-all duration-300 border-2 border-dashed border-emerald-200 hover:border-emerald-300 hover:shadow-lg rounded-lg"
    >
      <Card className="w-full h-full border-none bg-emerald-50/30 hover:bg-emerald-50/50">
        <div className="aspect-[4/3] w-full flex items-center justify-center">
          <div className="text-center space-y-2">
            <Camera className="h-12 w-12 text-emerald-600 mx-auto group-hover:scale-110 transition-transform" />
            <p className="text-emerald-600 font-medium">Upload a Tree</p>
          </div>
        </div>
      </Card>
    </Link>
  );

  return (
    <div className="space-y-8">
      <div className="flex flex-col items-center justify-center max-w-md mx-auto space-y-8">
        <div className="text-center space-y-3">
          <TreePine className="h-16 w-16 text-emerald-600 mx-auto" />
          <h2 className="text-2xl font-semibold text-emerald-700">Welcome to Your Library</h2>
          {trees.length === 0 && (
            <p className="text-emerald-600/80">To get started, upload your first tree</p>
          )}
        </div>
      </div>
   
      <div className={`${trees.length === 0 ? 'max-w-md mx-auto' : 'grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6'}`}>
        <UploadCard />
        
        {trees.map((tree) => {
          const details = treeDetailsCache[tree.id ?? ''];
          const imageUrl = details?.images?.[0]?.id ? imageUrls[details.images[0].id] : null;
          const treeName = details?.analyses?.tree_type || 'this tree';
          const isAnalyzing = analyzingTreeId === tree.id;
   
          return (
            <Card key={tree.id} className="overflow-hidden group hover:shadow-lg transition-all duration-300">
              <div 
                className="relative aspect-[4/3] bg-emerald-50 cursor-pointer"
                onClick={() => navigate(`/trees/${tree.id}`)}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={treeName}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <TreePine className="h-12 w-12 text-emerald-300" />
                  </div>
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
   
              <CardContent className="p-4">
                <div className="text-sm text-gray-500">
                  {new Date(tree.createdAt ?? '').toLocaleDateString()}
                </div>
                {details?.analyses?.tree_type && (
                  <div className="mt-1 text-lg font-medium text-emerald-700">
                    {details.analyses.tree_type}
                  </div>
                )}
              </CardContent>
   
              <CardFooter className="p-4 pt-0 flex flex-col gap-2">
              <div className="flex justify-between gap-2 w-full">
                  <Button
                    variant="outline"
                    size="sm"
                    className="flex-1"
                    onClick={() => updateTree({ ...tree, isPublic: !tree.isPublic })}
                  >
                    <Share2 className="h-5 w-5 mr-2" />
                    {tree.isPublic ? 'Public' : 'Private'}
                  </Button>

                  {tree.isPublic && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={async () => {
                        try {
                          await copyToClipboard(
                            `${window.location.origin}/trees/public/${tree.id}`,
                            (options) => toast({
                              ...options,
                              className: "bg-white border-l-4 border-l-emerald-500 shadow-md",
                              description: "Link copied to clipboard",
                              duration: 2000
                            })
                          );
                        } catch (error) {
                          console.error('Error sharing tree:', error);
                        }
                      }}
                    >
                      <Share2 className="h-5 w-5" />
                    </Button>
                  )}
     
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="text-red-600 hover:text-red-700"
                    onClick={() => {
                      setTreeToDelete({ id: tree.id, name: treeName });
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <Trash2 className="h-5 w-5" />
                  </Button>
                </div>
                
                {!details?.analyses?.tree_type && (
                  <Button
                    variant="secondary"
                    size="sm"
                    className="w-full bg-emerald-50 hover:bg-emerald-100 text-emerald-700"
                    onClick={() => handleAnalyzeClick(tree.id)}
                    disabled={isAnalyzing}
                  >
                    {isAnalyzing ? (
                      <Loader2 className="h-5 w-5 mr-2 animate-spin" />
                    ) : (
                      <Plus className="h-5 w-5 mr-2" />
                    )}
                    {isAnalyzing ? 'Analyzing...' : 'Start Analysis'}
                  </Button>
                )}
              </CardFooter>
            </Card>
          );
        })}
      </div>
   
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => {
          if (treeToDelete?.id) {
            deleteTree(treeToDelete.id);
          }
          setIsDeleteModalOpen(false);
          setTreeToDelete(null);
        }}
        treeId={treeToDelete?.id}
        treeName={treeToDelete?.name}
      />
    </div>
  );
}

export default TreeList;