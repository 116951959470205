import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Button } from "./ui/button";
import { Camera, TreePine, ImagePlus, Loader2, X, Sparkles, Info } from 'lucide-react';
import { Progress } from "./ui/progress";
import { cn } from "../lib/utils";

interface UploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (files: File[]) => void;
  uploadProgress: number;
  isUploading: boolean;
}

const UploadDialog: React.FC<UploadDialogProps> = ({
  isOpen,
  onClose,
  onUpload,
  uploadProgress,
  isUploading
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [previewFiles, setPreviewFiles] = useState<File[]>([]);
  
  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files).slice(0, 3);
      setPreviewFiles(files);
      onUpload(files);
    }
  }, [onUpload]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files).slice(0, 3);
      setPreviewFiles(files);
      onUpload(files);
    }
  }, [onUpload]);

  const openCamera = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment';
    input.multiple = true;
    input.onchange = (e) => handleChange(e as any);
    input.click();
  };

  const renderUploadingState = () => (
    <div className="space-y-8 p-4">
      <div className="bg-gradient-to-br from-emerald-50 to-emerald-100/50 rounded-3xl p-8">
        <div className="space-y-8">
          <div className="flex items-center justify-center">
            <div className="relative">
              <div className="absolute inset-0 bg-emerald-200 rounded-full blur-lg opacity-30" />
              <Loader2 className="h-20 w-20 text-emerald-500 animate-spin" />
            </div>
          </div>
          
          <div className="space-y-6">
            <Progress value={uploadProgress} className="h-4 w-full" />
            <div className="flex justify-between text-xl">
              <span className="text-emerald-700 font-medium">Uploading photos...</span>
              <span className="text-emerald-600">{Math.round(uploadProgress)}%</span>
            </div>

            {previewFiles.length > 0 && (
              <div className="space-y-4">
                <p className="text-center text-xl text-emerald-600">
                  Processing {previewFiles.length} {previewFiles.length === 1 ? 'photo' : 'photos'}
                </p>
                <div className="flex gap-4 overflow-x-auto py-2">
                  {Array.from(previewFiles).map((file, index) => (
                    <div 
                      key={index}
                      className="relative w-24 h-24 flex-shrink-0 rounded-2xl overflow-hidden border-2 border-emerald-200"
                    >
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-cover"
                        onLoad={(e) => URL.revokeObjectURL((e.target as HTMLImageElement).src)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderUploadState = () => (
    <div className="space-y-6 p-4">
      {/* Photo Tips */}
      <div className="bg-blue-50 rounded-3xl p-6">
        <div className="flex items-start gap-4">
          <Info className="h-6 w-6 text-blue-500 flex-shrink-0 mt-1" />
          <div>
            <h3 className="text-lg font-medium text-blue-700 mb-2">
              Get the best identification by uploading 1-3 photos:
            </h3>
            <ul className="space-y-3 text-blue-600">
              <li className="flex items-center gap-2">
                <div className="w-2 h-2 bg-blue-400 rounded-full" />
                Close-up of leaves or bark
              </li>
              <li className="flex items-center gap-2">
                <div className="w-2 h-2 bg-blue-400 rounded-full" />
                Full view of the tree
              </li>
              <li className="flex items-center gap-2">
                <div className="w-2 h-2 bg-blue-400 rounded-full" />
                Any distinctive features
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Upload Area */}
      <div
        className={cn(
          "rounded-3xl border-3 border-dashed transition-all duration-300",
          "hover:border-emerald-300 hover:bg-emerald-50/30",
          dragActive ? "border-emerald-400 bg-emerald-50/50 scale-[1.02]" : "border-emerald-200"
        )}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center gap-8 p-8">
          <div className="flex flex-col gap-6 w-full">
            {/* Take Photos Button */}
            <button
              onClick={openCamera}
              className="w-full flex flex-col items-center gap-4 p-6 
                       bg-gradient-to-r from-emerald-500 to-emerald-600
                       hover:from-emerald-600 hover:to-emerald-700
                       rounded-2xl text-white text-xl font-medium
                       active:scale-95 transition-all duration-200"
            >
              <Camera className="h-12 w-12" />
              Take Photos
            </button>

            {/* Choose Files Button */}
            <button
              onClick={() => document.getElementById('file-upload')?.click()}
              className="w-full flex flex-col items-center gap-4 p-6
                       bg-emerald-50 text-emerald-700 rounded-2xl text-xl
                       font-medium border-2 border-emerald-200
                       hover:bg-emerald-100 active:scale-95 
                       transition-all duration-200"
            >
              <ImagePlus className="h-12 w-12" />
              Choose Files
            </button>
          </div>
        </div>

        <input
          id="file-upload"
          type="file"
          className="hidden"
          multiple
          accept="image/*"
          onChange={handleChange}
          max="3"
        />
      </div>

      {/* File Limits */}
      <div className="text-center space-y-2 text-emerald-600">
        <p className="text-lg">Maximum 3 photos, 10MB each</p>
        <p className="text-base">Accepts JPG, PNG</p>
      </div>
    </div>
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-xl bg-white/95 backdrop-blur-xl p-0">
        <DialogHeader className="p-6 flex-row justify-between items-center space-y-0 relative">
          <div className="flex items-center gap-3">
            <div className="bg-gradient-to-br from-emerald-400 to-emerald-600 p-3 rounded-xl">
              <TreePine className="h-8 w-8 text-white" />
            </div>
            <div>
              <DialogTitle className="text-2xl font-bold bg-gradient-to-r from-emerald-800 to-emerald-600 bg-clip-text text-transparent">
                Upload Tree Photos
              </DialogTitle>
            </div>
          </div>
        </DialogHeader>
        
        <div className="transition-all duration-300">
          {isUploading ? renderUploadingState() : renderUploadState()}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadDialog;