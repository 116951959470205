import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/app-context';
import Analysis from '../components/analysis';
import { Button } from '../components/ui/button';
import { ChevronLeft, TreePine, MessageCircle } from 'lucide-react';
import Navigation from '../components/navigation';
import { useMobile } from '../contexts/app-context';
import TreeGallery from '../components/tree-gallery';

const TreeDetail = () => {
  const isMobile = useMobile();
  const { treeId } = useParams();
  const navigate = useNavigate();
  const { 
    selectedTree, 
    isLoading,
    setSelectedTree,
    treeDetailsCache,
    loadTreeDetails,
    setIsChatMinimized,
    isChatMinimized,
    imageUrls
  } = useApp();

  useEffect(() => {
    if (!treeId) return;

    const loadTree = async () => {
      const treeDetails = await loadTreeDetails(treeId);
      if (treeDetails) {
        setSelectedTree(treeDetails);
      }
    };

    // Only load if we don't have the tree in cache or selected
    if (!treeDetailsCache[treeId] || !selectedTree || selectedTree.id !== treeId) {
      loadTree();
    } else if (selectedTree.id !== treeId) {
      // If we have the tree in cache but it's not selected, update selected
      setSelectedTree(treeDetailsCache[treeId]);
    }
  }, [treeId, treeDetailsCache, selectedTree, loadTreeDetails, setSelectedTree]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-emerald-50 to-teal-50">
        <Navigation onUploadClick={() => {}} />
        <div className="flex-1 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-4 border-emerald-500 border-t-transparent" />
        </div>
      </div>
    );
  }

  if (!selectedTree || !treeId) {
    return (
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-emerald-50 to-teal-50">
        <Navigation onUploadClick={() => {}} />
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center space-y-4">
            <div className="bg-emerald-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto">
              <TreePine className="h-8 w-8 text-emerald-600" />
            </div>
            <p className="text-lg text-gray-600">Tree not found</p>
            <Button 
              variant="outline" 
              onClick={() => navigate('/dashboard')}
              className="text-emerald-600 hover:text-emerald-700"
            >
              Return to Dashboard
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-emerald-50 to-teal-50">
      <Navigation onUploadClick={() => {}} />
      
      <div className="flex-1 w-full max-w-7xl mx-auto px-4 py-6">
        <Button
          variant="ghost"
          onClick={() => navigate('/dashboard')}
          className="mb-4 text-emerald-700 hover:text-emerald-800"
        >
          <ChevronLeft className="h-4 w-4 mr-2" />
          Back to Dashboard
        </Button>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="relative">
              <TreeGallery 
                images={selectedTree.images || []}
                imageUrls={imageUrls}
              />
              {selectedTree?.id && (
                <Button
                  onClick={() => setIsChatMinimized(!isChatMinimized)}
                  className="absolute bottom-4 right-4 bg-white/90 backdrop-blur hover:bg-white/95 text-emerald-600 border border-emerald-200 shadow-lg"
                  size={isMobile ? "lg" : "default"}
                >
                  <MessageCircle className={`${isMobile ? 'h-6 w-6' : 'h-4 w-4'} mr-2`} />
                  Chat with Tree
                </Button>
              )}
            </div>
          </div>

          <Analysis />
        </div>
      </div>
    </div>
  );
};

export default TreeDetail;