import React from 'react';
import { Toaster } from './ui/toaster';
import { Toast } from './ui/toast';
import { CheckCircle2, Copy, AlertCircle } from 'lucide-react';
import { useMobile } from '../contexts/app-context';

export const showSuccessToast = (toast: any, isMobile: boolean) => {
  toast({
    duration: 3000,
    title: "Link Copied!",
    description: "The sharing link has been copied to your clipboard.",
    className: `
      fixed 
      ${isMobile ? 'bottom-6 left-4 right-4' : 'bottom-6 right-6'} 
      bg-emerald-50 
      border-2 
      border-emerald-600 
      shadow-xl 
      rounded-xl
      z-50
    `,
    action: (
      <div className="flex items-center gap-2">
        <div className="bg-emerald-200 p-2 rounded-full">
          <CheckCircle2 className={`${isMobile ? 'h-6 w-6' : 'h-5 w-5'} text-emerald-700`} />
        </div>
        <div className="ml-2">
          <Copy className={`${isMobile ? 'h-5 w-5' : 'h-4 w-4'} text-emerald-700`} />
        </div>
      </div>
    ),
  });
};

export const showErrorToast = (toast: any, isMobile: boolean) => {
  toast({
    duration: 3000,
    variant: "destructive",
    title: "Failed to Copy",
    description: "There was an error copying the link.",
    className: `
      fixed 
      ${isMobile ? 'bottom-6 left-4 right-4' : 'bottom-6 right-6'} 
      bg-red-50 
      border-2 
      border-red-600 
      shadow-xl 
      rounded-xl
      z-50
    `,
    action: (
      <div className="flex items-center gap-2">
        <div className="bg-red-200 p-2 rounded-full">
          <AlertCircle className={`${isMobile ? 'h-6 w-6' : 'h-5 w-5'} text-red-700`} />
        </div>
      </div>
    ),
  });
};

export const copyToClipboard = async (text: string, toast: any) => {
  const isMobile = window.innerWidth < 768; // Basic mobile detection
  try {
    await navigator.clipboard.writeText(text);
    showSuccessToast(toast, isMobile);
    return true;
  } catch (err) {
    showErrorToast(toast, isMobile);
    return false;
  }
};

export default function ToastProvider() {
  return <Toaster />;
}